import { Helper, Link, Table } from 'akeneo-design-system';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import { useDispatch, useSelector } from '../store/StoreProvider';
import { AssetAttributeItem } from './AssetAttributeItem';
import { useQuery } from 'react-query';
import { getPimAssetAttributes } from '../api/getPimAssetAttributes';
import { useCurrentStore } from '../../../../MultiStore';
import { Loading } from '../../../../../component';
import { helpCenterMediaMappingUrl } from '../../../../../util/config';

const Space = styled(Table.HeaderCell)`
    width: 100%;
`;

type Props = {
    maxAttribute: number;
};

export const AssetAttributeList = ({ maxAttribute }: Props) => {
    const { assetMapping, mediaAssetCollectionAttributes } = useSelector(
        (state) => state
    );
    const dispatch = useDispatch();

    const { currentStoreId } = useCurrentStore();

    const { isFetching: isFetchingAsset } = useQuery(
        [currentStoreId, 'getPimAssetAttributes'],
        () => getPimAssetAttributes(currentStoreId),
        {
            onSuccess: (result) => {
                dispatch({
                    type: 'assetMapping/fetch/collections',
                    assetCollectionAttributes: result.assetCollectionAttributes,
                });
                dispatch({
                    type: 'assetMapping/fetch/attributes',
                    assetAttributesPerAssetFamily:
                        result.assetAttributesPerAssetFamily,
                });
            },
        }
    );

    const handleSort = (newIndices: number[]): void => {
        dispatch({
            type: 'assetMapping/mapping/sort',
            newIndices: newIndices,
        });
    };

    return (
        <>
            {isFetchingAsset ? (
                <Loading />
            ) : (
                <>
                    <Table
                        isDragAndDroppable={true}
                        onReorder={(newIndices) => {
                            handleSort(newIndices);
                        }}
                    >
                        <Table.Header>
                            <Table.HeaderCell>
                                <FormattedMessage
                                    id='0mJgQP'
                                    defaultMessage='Asset collection'
                                />
                            </Table.HeaderCell>
                            <Table.HeaderCell>
                                <FormattedMessage
                                    id='IqlC43'
                                    defaultMessage='Asset attribute'
                                />
                            </Table.HeaderCell>
                            <Table.HeaderCell>
                                <FormattedMessage
                                    id='KjJVAm'
                                    defaultMessage='Asset attribute type
                            '
                                />
                            </Table.HeaderCell>
                            <Table.HeaderCell>
                                <FormattedMessage
                                    id='4zRIAo'
                                    defaultMessage='Media type
                            '
                                />
                            </Table.HeaderCell>
                            <Space />
                        </Table.Header>
                        <Table.Body>
                            {assetMapping.map((attributeCode, index) => {
                                const assetCollections =
                                    mediaAssetCollectionAttributes.get(
                                        attributeCode.attribute_code
                                    );
                                if (assetCollections !== undefined) {
                                    return (
                                        <Table.Row
                                            key={attributeCode.attribute_code}
                                        >
                                            <AssetAttributeItem
                                                assetCollections={
                                                    assetCollections
                                                }
                                                assetAttribute={
                                                    attributeCode.asset_attribute_code
                                                }
                                                index={index}
                                            />
                                        </Table.Row>
                                    );
                                }
                                return <></>;
                            })}
                            {assetMapping.length !== maxAttribute && (
                                <Table.Row key={'newAttribute'}>
                                    <AssetAttributeItem
                                        key={assetMapping.length}
                                        assetCollections={null}
                                        assetAttribute={null}
                                        index={null}
                                    />
                                </Table.Row>
                            )}
                        </Table.Body>
                    </Table>
                    {assetMapping.length === maxAttribute && (
                        <Helper level='warning'>
                            <FormattedMessage
                                id='K9cMz0'
                                values={{
                                    maxImagesAttributes: maxAttribute,
                                }}
                                defaultMessage='You have reached the limit of {maxImagesAttributes} asset collections per product.'
                            />{' '}
                            <Link
                                href={helpCenterMediaMappingUrl}
                                target='_blank'
                            >
                                <FormattedMessage
                                    id='i/qJsQ'
                                    defaultMessage='Please check the Help Center for more information.'
                                />
                            </Link>
                        </Helper>
                    )}
                </>
            )}
        </>
    );
};
