import {
    AddingValueIllustration,
    Button,
    Information,
    Link,
    SectionTitle,
    Table,
} from 'akeneo-design-system';
import { FormattedMessage, useIntl } from 'react-intl';
import { useQuery } from 'react-query';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { getStores } from '../api/getStores';
import React from 'react';
import { useDeleteStore } from '../hook/useDeleteStore';
import { retrieveStoreWithSynchronizationRunning } from '../api/retrieveStoreWithSynchronizationRunning';
import { NoStore } from './NoStore';
import { StoreLine } from './StoreLine';
import { helpCenterStoreListUrl } from '../../../util/config';

const MAX_STORE = 100;

const CreateButtonContainer = styled.div``;

export const StoresSelection = () => {
    const intl = useIntl();
    const navigate = useNavigate();
    const { deleteStore } = useDeleteStore();

    const { data: storeList, refetch: storeListRefetch } = useQuery(
        'getStores',
        getStores
    );
    const { data: storesWithSynchronizationRunning } = useQuery(
        'storesWithSynchronizationRunning',
        retrieveStoreWithSynchronizationRunning,
        {
            refetchInterval: 5000,
        }
    );

    const handleStoreDeletion = async (storeId: string) => {
        await deleteStore(storeId);
        await storeListRefetch();
    };

    return (
        <>
            <Information
                illustration={<AddingValueIllustration />}
                title={intl.formatMessage({
                    defaultMessage:
                        'Connect a single PIM with multiple BigCommerce stores',
                    id: 'wdeFYx',
                })}
            >
                <FormattedMessage
                    id='V/r10X'
                    defaultMessage='Multi-store feature offers you the ability to synchronize your PIM with multiple BigCommerce stores.'
                />
                <br />
                <FormattedMessage
                    id='LEdi69'
                    defaultMessage='To connect your first catalog, simply create your destination store by clicking on the "Create a new store" button.'
                />
                <br />
                <Link href={helpCenterStoreListUrl} target='_blank'>
                    <FormattedMessage
                        id='5q2nke'
                        defaultMessage='Check out our Help Center for more information'
                    />
                </Link>
            </Information>
            <br />
            <SectionTitle>
                <SectionTitle.Title>
                    <FormattedMessage id='SHShT7' defaultMessage='Store list' />
                </SectionTitle.Title>

                <SectionTitle.Spacer />

                <CreateButtonContainer>
                    <Button
                        level='primary'
                        onClick={() => navigate('create')}
                        disabled={storeList && storeList.length >= MAX_STORE}
                    >
                        <FormattedMessage
                            id='KvaPsB'
                            defaultMessage='CREATE A NEW STORE'
                        />
                    </Button>
                </CreateButtonContainer>
            </SectionTitle>

            <br />

            <Table>
                <Table.Header>
                    <Table.HeaderCell>
                        <FormattedMessage
                            id='IUqPi4'
                            defaultMessage='Store name
                            '
                        />
                    </Table.HeaderCell>
                    <Table.HeaderCell>
                        <FormattedMessage
                            id='1f7SOL'
                            defaultMessage='Connection status'
                        />
                    </Table.HeaderCell>
                    <Table.HeaderCell>
                        <FormattedMessage
                            id='vy4ite'
                            defaultMessage='Last synchronization'
                        />
                    </Table.HeaderCell>
                    <Table.HeaderCell>
                        <FormattedMessage
                            id='JJJIez'
                            defaultMessage='Last synchronization status'
                        />
                    </Table.HeaderCell>
                    <Table.HeaderCell></Table.HeaderCell>
                </Table.Header>
                <Table.Body>
                    {storeList?.map((store) => (
                        <StoreLine
                            store={store}
                            key={store.id}
                            handleStoreDeletion={handleStoreDeletion}
                            isStoreDeletionDisabled={
                                storesWithSynchronizationRunning?.includes(
                                    store.id
                                ) ?? false
                            }
                        />
                    ))}
                </Table.Body>
            </Table>
            {storeList?.length === 0 && <NoStore></NoStore>}
        </>
    );
};
