import { Helper, SectionTitle } from 'akeneo-design-system';
import { FormattedMessage } from 'react-intl';
import { useQuery } from 'react-query';
import styled from 'styled-components';
import { Loading } from '../../../component';
import { useCurrentStore } from '../../MultiStore';
import { fetchBCVariantAttributes } from '../api/fetchBCVariantAttributes';
import { MappingList } from '../component/MappingList';
import { AttributeMappingItem } from './AttributeMappingItem';
import { MediaMappingItem } from './MediaMappingItem';

export const ProductVariantMapping = () => {
    const { currentStoreId } = useCurrentStore();
    const { isLoading, data } = useQuery(
        [currentStoreId, 'fetchBCVariantAttributes'],
        () => {
            return fetchBCVariantAttributes(currentStoreId);
        }
    );

    return (
        <Container>
            <SectionTitle>
                <SectionTitle.Title>
                    <FormattedMessage
                        id='+WiwPE'
                        defaultMessage='Mapping of  product variants'
                    />
                </SectionTitle.Title>
            </SectionTitle>

            <div>
                <Helper level='info'>
                    <FormattedMessage
                        id='62hmBE'
                        defaultMessage='In this section you will define the mapping for the product variants (Product options in BigCommerce). As soon as you define a product variant value as a source from Akeneo PIM, it will always be recorded over a product model value.'
                    />
                </Helper>
            </div>
            {isLoading ? (
                <Loading />
            ) : (
                <MappingList>
                    {Object.values(data || []).map((bcVariantAttribute) => {
                        if (bcVariantAttribute.code === 'IMAGE') {
                            return (
                                <MediaMappingItem
                                    key={bcVariantAttribute.code}
                                    bcVariantAttribute={bcVariantAttribute}
                                />
                            );
                        }

                        return (
                            <AttributeMappingItem
                                key={bcVariantAttribute.code}
                                bcVariantAttribute={bcVariantAttribute}
                            />
                        );
                    })}
                </MappingList>
            )}
        </Container>
    );
};

const Container = styled.div`
    margin-top: 20px;
`;
