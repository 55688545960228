import { Helper, SectionTitle } from 'akeneo-design-system';
import { FormattedMessage } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import { EditMediaMappingButton } from '../../component/MediaMapping/EditMediaMappingButton';
import { useSelector } from '../store/StoreProvider';
import { MappingItem } from './component/MappingItem';
import { MappingList } from '../../component/MappingList';
import { HasAttributePerFamilyToggle } from './HasAttributePerFamilyToggle';
import { PimAttributeSelector } from './PimAttributeSelector';
import { MappingSection } from './component/MappingSection';
import React from 'react';

export const ProductMapping = () => {
    const bcAttributes = useSelector((state) => state.bcAttributes);
    const bcAttributeSections = useSelector(
        (state) => state.bcAttributeSections
    );

    const endSectionStyle = {
        paddingTop: '40px',
        display: 'block',
    };
    const navigate = useNavigate();

    return (
        <>
            <SectionTitle>
                <SectionTitle.Title>
                    <FormattedMessage
                        id='p84Gau'
                        defaultMessage='Mapping of simple products and product models'
                    />
                </SectionTitle.Title>
            </SectionTitle>

            <Helper level='info'>
                <FormattedMessage
                    id='HHRBvy'
                    defaultMessage='Respecting BigCommerce business rules is required to guarantee the synchronization.'
                />
            </Helper>

            <MappingList>
                {bcAttributeSections &&
                    Object.entries(bcAttributeSections).map(([key, values]) => (
                        <React.Fragment key={key}>
                            <MappingSection sectionLabel={key} />
                            {Object.values(values).map((value) => (
                                <MappingItem
                                    key={value + bcAttributes[value]?.code}
                                    bcAttribute={bcAttributes[value]!}
                                >
                                    {!bcAttributes[value]?.collection && (
                                        <PimAttributeSelector
                                            bcAttribute={bcAttributes[value]!}
                                        />
                                    )}
                                    {bcAttributes[value]?.code === 'IMAGES' && (
                                        <div>
                                            <EditMediaMappingButton
                                                onClick={() =>
                                                    navigate('media-mapping')
                                                }
                                            />
                                        </div>
                                    )}
                                    {bcAttributes[value]?.code !== 'IMAGES' && (
                                        <HasAttributePerFamilyToggle
                                            bcAttributeCode={
                                                bcAttributes[value]?.code!
                                            }
                                        />
                                    )}
                                </MappingItem>
                            ))}
                            <tr style={endSectionStyle} />
                        </React.Fragment>
                    ))}
            </MappingList>
        </>
    );
};
