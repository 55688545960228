import { apiFetch } from '../../../../util/apiFetch';
import { MediaSource } from '../../model/MediaSource';
import { MediaMapping } from '../model/MediaMapping';

export const saveMediaMapping = async ({
    storeId,
    mapping,
}: {
    storeId: string;
    mapping: MediaMapping;
}) => {
    let data = null;
    if (
        mapping.source === MediaSource.Images &&
        mapping.attributeCode !== null
    ) {
        data = mapping.attributeCode;
    } else if (
        mapping.source === MediaSource.Assets &&
        mapping.attributeCode !== null &&
        mapping.assetAttributeCode !== null
    ) {
        data = {
            attribute_code: mapping.attributeCode,
            asset_attribute_code: mapping.assetAttributeCode,
        };
    }

    await apiFetch(`${storeId}/mapping/saveProductVariantMapping`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            IMAGE: { data },
        }),
    });
};
