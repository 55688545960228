import { Button } from 'akeneo-design-system';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useSaveConfiguration } from './hook/useSaveConfiguration';
import { State } from './store/reducer';
import { useSelector } from './store/StoreProvider';

const selectIsDirty = (state: State) =>
    state.bcCredentialsForm.isDirty ||
    state.connectorConfigForm.isDirty ||
    state.connectorBcConfigForm.isDirty;

export const SaveConfigurationButton = () => {
    const { save, isSaving } = useSaveConfiguration();

    const isDirty = useSelector(selectIsDirty);

    const handleSave = async () => {
        await save();
    };

    return (
        <>
            <Button onClick={handleSave} disabled={isSaving || !isDirty}>
                <FormattedMessage id='jvo0vs' defaultMessage='Save' />
            </Button>
        </>
    );
};
