import { useLocation, useNavigate } from 'react-router-dom';
import { CloseActionButtons } from './component/CloseActionButtons';
import { DetailedModalReportContent } from './component/DetailedModalReportContent';
import { useIntl } from 'react-intl';
import { LogStatus } from '../model/LogStatus';
import { CustomModal } from '../../../component';

export type Log = {
    code: string;
    impacted_item: number;
    label: string;
    message: string;
};

export const DetailedModalReport = () => {
    const location = useLocation();
    const intl = useIntl();
    const navigate = useNavigate();
    const navigateToPreviousPage = async () => {
        navigate(-1);
    };
    const handleCancel = () => {
        navigateToPreviousPage();
    };

    let title: string;
    switch (location.state.status) {
        case LogStatus.Info:
            title = intl.formatMessage({
                defaultMessage: 'Info Details',
                id: 'Oi7tpI',
            });
            break;
        case LogStatus.Warning:
            title = intl.formatMessage({
                defaultMessage: 'Warning Details',
                id: '7UnBOd',
            });
            break;
        default: // LogStatus.Error
            title = intl.formatMessage({
                defaultMessage: 'Error Details',
                id: 'Ym5cCU',
            });
            break;
    }

    const logsItem = location.state.logsItem as Log;

    return (
        <>
            <CustomModal
                title={title}
                subTitle={logsItem.label}
                actions={<CloseActionButtons onCancel={handleCancel} />}
            >
                <DetailedModalReportContent
                    status={location.state.status}
                    synchronizationId={location.state.synchronizationId}
                    logsItem={logsItem}
                />
            </CustomModal>
        </>
    );
};
