import { Route, Routes } from 'react-router-dom';
import { CustomFieldsSelected } from './component/CustomFieldsSelected';
import { CustomFieldsSelection } from './component/CustomFieldsSelection';
import { DisabledMapping } from '../NativeFields/component/DisabledMapping';
import { InvalidConfiguration } from '../NativeFields/component/InvalidConfiguration';
import React from 'react';
import { RunningSynchronizationGuard } from '../Synchronization';
import { ValidConfigurationGuard } from '../Configuration';

export const CustomFields = () => {
    return (
        <RunningSynchronizationGuard fallback={() => <DisabledMapping />}>
            <ValidConfigurationGuard
                fallback={(
                    isBCCredentialsInvalid,
                    isConfigInvalid,
                    isBcConfigInvalid
                ) => (
                    <InvalidConfiguration
                        isBCCredentialsInvalid={isBCCredentialsInvalid}
                        isConfigInvalid={isConfigInvalid}
                        isBcConfigInvalid={isBcConfigInvalid}
                    />
                )}
            >
                <Routes>
                    <Route
                        path='selection'
                        element={<CustomFieldsSelection />}
                    />
                    <Route path='*' element={<CustomFieldsSelected />} />
                </Routes>
            </ValidConfigurationGuard>
        </RunningSynchronizationGuard>
    );
};
