import { MappingItem } from './component/MappingItem';
import { MappingState } from './component/MappingState';
import { BCVariantAttribute } from './model/BCVariantAttribute';
import { PimVariantAttributeSelector } from './PimVariantAttributeSelector';
import { State } from './store/reducer';
import { useSelector } from './store/StoreProvider';

type Props = {
    bcVariantAttribute: BCVariantAttribute;
};

export const AttributeMappingItem = ({ bcVariantAttribute }: Props) => {
    const isMapped = useSelector(
        (state: State) =>
            !!state.productVariantMapping.get(bcVariantAttribute.code)?.data
    );

    return (
        <MappingItem
            key={bcVariantAttribute.code}
            bcVariantAttribute={bcVariantAttribute}
            mappingState={isMapped && <MappingState status='complete' />}
        >
            <PimVariantAttributeSelector
                bcVariantAttribute={bcVariantAttribute}
            />
        </MappingItem>
    );
};
