import {
    CloseIcon,
    IconButton,
    SelectInput,
    Table,
} from 'akeneo-design-system';
import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from '../store/StoreProvider';
import styled from 'styled-components';
import { AssetCollectionAttributes } from '../model/AssetCollectionAttributes';
import { AssetAttributesPerAssetFamily } from '../model/AssetAttributesPerAssetFamily';

const AttributeSelectContainer = styled.div`
    width: 350px;
`;

const AttributeTextContainer = styled.div`
    width: 100%;
`;

const RemoveButtonContainer = styled.div`
    display: flex;
    justify-content: end;
    width: 100%;
`;

type Props = {
    assetCollections: AssetCollectionAttributes | null;
    assetAttribute: string | null;
    index: number | null;
};

export const AssetAttributeItem = ({
    assetCollections,
    assetAttribute,
    index,
}: Props) => {
    const intl = useIntl();
    const { allowedAssetCollections } = useSelector((state) => state);
    const { mediaAssetAttributesPerAssetFamily } = useSelector(
        (state) => state
    );
    const dispatch = useDispatch();
    const [selectedAssetAttribute, setAssetAttribute] = useState<string | null>(
        assetAttribute
    );

    const handleChange = (
        newValue: string | null,
        newAssetAttribute: string | null
    ) => {
        if (!assetCollections && newValue && !newAssetAttribute) {
            dispatch({
                type: 'assetMapping/mapping/new',
                newAttribute: newValue,
            });
        }

        if (assetCollections && newValue && index !== null) {
            dispatch({
                type: 'assetMapping/mapping/update',
                index: index,
                previousValue: assetCollections.code,
                newValue: newValue,
                newassertAttribute: null,
            });
        }
        if (
            assetCollections &&
            !newValue &&
            index !== null &&
            newAssetAttribute
        ) {
            dispatch({
                type: 'assetMapping/mapping/update',
                index: index,
                previousValue: assetCollections.code,
                newValue: assetCollections.code,
                newassertAttribute: newAssetAttribute,
            });
            setAssetAttribute(newAssetAttribute);
        }
    };

    const handleRemove = () => {
        if (assetCollections) {
            dispatch({
                type: 'assetMapping/mapping/remove',
                attributeToRemove: assetCollections.code,
            });
        }
    };

    let selectAssetAttributes:
        | readonly {
              readonly code: string;
              readonly type: string;
              readonly label: string;
              readonly mediaType: string;
          }[]
        | null
        | undefined = null;
    if (assetCollections) {
        selectAssetAttributes = mediaAssetAttributesPerAssetFamily.get(
            assetCollections.familyCode
        );
    }

    return (
        <>
            {/* Asset collection */}
            <Table.Cell>
                <AttributeSelectContainer>
                    <SelectInput
                        emptyResultLabel={intl.formatMessage({
                            id: 'eO+rkS',
                            defaultMessage: 'No asset collection to map',
                        })}
                        placeholder={intl.formatMessage({
                            id: 'WB41Gj',
                            defaultMessage: 'Select an asset collection',
                        })}
                        value={assetCollections?.label ?? null}
                        openLabel={''}
                        onChange={(newValue: string) =>
                            handleChange(newValue, null)
                        }
                        clearable={false}
                    >
                        {Array.from(allowedAssetCollections.values())
                            .sort((i1, i2) => (i1.label > i2.label ? 1 : -1))
                            .map((assetCollections) => (
                                <SelectInput.Option
                                    key={assetCollections.code}
                                    value={assetCollections.code}
                                >
                                    {assetCollections.label}
                                </SelectInput.Option>
                            ))}
                    </SelectInput>
                </AttributeSelectContainer>
            </Table.Cell>

            {/* Asset attribute */}
            {assetCollections ? (
                <Table.Cell>
                    <AttributeSelectContainer>
                        <SelectInput
                            emptyResultLabel={intl.formatMessage({
                                id: 'ckXs8S',
                                defaultMessage: 'No asset attribute to map',
                            })}
                            placeholder={intl.formatMessage({
                                id: '4CLSQB',
                                defaultMessage: 'Select an asset attribute',
                            })}
                            value={
                                (selectedAssetAttribute === ''
                                    ? null
                                    : selectedAssetAttribute) ?? null
                            }
                            openLabel={''}
                            onChange={(newassertAttribute: string) =>
                                handleChange(null, newassertAttribute)
                            }
                            clearable={false}
                        >
                            {selectAssetAttributes?.map(
                                (
                                    assetAttributesPerAssetFamily: AssetAttributesPerAssetFamily
                                ) => (
                                    <SelectInput.Option
                                        key={assetAttributesPerAssetFamily.code}
                                        value={
                                            assetAttributesPerAssetFamily.code
                                        }
                                    >
                                        {assetAttributesPerAssetFamily.label}
                                    </SelectInput.Option>
                                )
                            )}
                        </SelectInput>
                    </AttributeSelectContainer>
                </Table.Cell>
            ) : (
                <Table.Cell>
                    <AttributeSelectContainer></AttributeSelectContainer>
                </Table.Cell>
            )}

            {selectedAssetAttribute ? (
                <Table.Cell>
                    <AttributeTextContainer>
                        {
                            selectAssetAttributes?.filter(
                                (attribute) =>
                                    attribute.code === selectedAssetAttribute
                            )[0]?.type
                        }
                    </AttributeTextContainer>
                </Table.Cell>
            ) : (
                <Table.Cell />
            )}

            {selectedAssetAttribute ? (
                <Table.Cell>
                    <AttributeTextContainer>
                        {
                            selectAssetAttributes?.filter(
                                (attribute) =>
                                    attribute.code === selectedAssetAttribute
                            )[0]?.mediaType
                        }
                    </AttributeTextContainer>
                </Table.Cell>
            ) : (
                <Table.Cell />
            )}

            {assetCollections ? (
                <Table.Cell>
                    <RemoveButtonContainer>
                        <IconButton
                            icon={<CloseIcon />}
                            title={intl.formatMessage({
                                id: 'S53Eji',
                                defaultMessage: 'Remove attribute',
                            })}
                            onClick={() => handleRemove()}
                            ghost='borderless'
                            level='tertiary'
                        />
                    </RemoveButtonContainer>
                </Table.Cell>
            ) : (
                <Table.Cell />
            )}
        </>
    );
};
