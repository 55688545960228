import React from 'react';
import styled from 'styled-components';
import { FlexColumn, FlexLayout } from '../../component/FlexLayout';
import { BCCredentialsForm } from './component/BCCredentialsForm';
import { ConnectorConfigForm } from './component/ConnectorConfigForm';
import { ConfigurationProvider } from './ConfigurationProvider';
import { SaveConfigurationButton } from './SaveConfigurationButton';
import { DisabledConfiguration } from './component/DisabledConfiguration';
import { RunningSynchronizationGuard } from '../Synchronization';
import { BCStorefrontForm } from './component/BCStorefrontForm';
import { BCVariantDisplayForm } from './component/BCVariantDisplayForm';
import { StoreConfigForm } from './component/StoreConfigForm';
import { Spacer } from '../../component';
import { ResetConfigurationButton } from './ResetConfigurationButton';
import { FeatureGuard } from '../../util/featureFlag';
import { ResetLinksButton } from './ResetLinksButton';
import { PreExistingCatalogMapping } from './component/PreExistingCatalogMapping';

export const Configuration = () => {
    return (
        <RunningSynchronizationGuard fallback={() => <DisabledConfiguration />}>
            <ConfigurationProvider>
                <Spacer />
                <ButtonContainer>
                    <SaveConfigurationButton />
                    <FeatureGuard feature={'reset_config'}>
                        <ResetConfigurationButton />
                        <ResetLinksButton />
                    </FeatureGuard>
                </ButtonContainer>
                <FlexLayout>
                    <FlexColumn>
                        <StoreConfigForm />
                        <br />
                        <ConnectorConfigForm />
                        <PreExistingCatalogMapping />
                    </FlexColumn>
                    <FlexColumn>
                        <BCCredentialsForm />
                        <BCStorefrontForm />
                        <BCVariantDisplayForm />
                    </FlexColumn>
                </FlexLayout>
            </ConfigurationProvider>
        </RunningSynchronizationGuard>
    );
};

const ButtonContainer = styled.div`
    display: flex;
    justify-content: flex-end;
`;
