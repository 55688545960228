import { Button, SectionTitle, TabBar } from 'akeneo-design-system';
import { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import { LogsModel } from '../model/SynchronizationReportModel';
import { LogStatus } from '../model/LogStatus';
import { LogsReportTableDetail } from './LogsReportTableDetail';

const SectionTitleLogs = styled(SectionTitle)`
    justify-content: space-between;
`;
const DownloadLogsButton = styled(Button)`
    padding: 0 15px;
    height: 32px;
    color: ${({ theme }) => theme.color.grey120};
    background-color: #ffffff;
    border-color: ${({ theme }) => theme.color.grey100};

    &:hover:not([disabled]) {
        color: ${({ theme }) => theme.color.grey140};
        background-color: ${({ theme }) => theme.color.grey20};
        border-color: ${({ theme }) => theme.color.grey120};
    }
`;
type Props = {
    logsReport: LogsModel | null;
    synchronizationId: string;
};

export const LogsReport = ({ logsReport, synchronizationId }: Props) => {
    let initLogCategorieStatus: string;
    if (logsReport!.error.length > 0) {
        initLogCategorieStatus = LogStatus.Error;
    } else if (logsReport!.warning.length > 0) {
        initLogCategorieStatus = LogStatus.Warning;
    } else {
        initLogCategorieStatus = LogStatus.Info;
    }
    const [logCategorieStatus, setStatus] = useState(initLogCategorieStatus);

    let logsItemReport;
    switch (logCategorieStatus) {
        case LogStatus.Info:
            logsItemReport = logsReport!.info;
            break;
        case LogStatus.Warning:
            logsItemReport = logsReport!.warning;
            break;
        default: // LogStatus.Error
            logsItemReport = logsReport!.error;
            break;
    }

    return (
        <>
            <SectionTitleLogs>
                <SectionTitle.Title>
                    <FormattedMessage id='SNuQo7' defaultMessage='Logs' />
                </SectionTitle.Title>
                <SectionTitle.Spacer />
                <DownloadLogsButton
                    level='secondary'
                    ghost
                    href={
                        window.location.protocol +
                        '//' +
                        window.location.host +
                        '/api-web/synchronization/' +
                        synchronizationId +
                        '/downloadSynchronizationLogs'
                    }
                    target='_blank'
                >
                    <FormattedMessage
                        id='4P3axO'
                        defaultMessage='Download all logs'
                    />
                </DownloadLogsButton>
            </SectionTitleLogs>

            <TabBar moreButtonTitle='More'>
                {logsReport!.error.length > 0 && (
                    <TabBar.Tab
                        isActive={logCategorieStatus === LogStatus.Error}
                        onClick={() => setStatus(LogStatus.Error)}
                    >
                        <FormattedMessage id='KN7zKn' defaultMessage='Error' />
                    </TabBar.Tab>
                )}
                {logsReport!.warning.length > 0 && (
                    <TabBar.Tab
                        isActive={logCategorieStatus === LogStatus.Warning}
                        onClick={() => setStatus(LogStatus.Warning)}
                    >
                        <FormattedMessage
                            id='3SVI5p'
                            defaultMessage='Warning'
                        />
                    </TabBar.Tab>
                )}
                {logsReport!.info.length > 0 && (
                    <TabBar.Tab
                        isActive={logCategorieStatus === LogStatus.Info}
                        onClick={() => setStatus(LogStatus.Info)}
                    >
                        <FormattedMessage id='we4Lby' defaultMessage='Info' />
                    </TabBar.Tab>
                )}
            </TabBar>

            <LogsReportTableDetail
                logsItems={logsItemReport}
                logCategorieStatus={logCategorieStatus}
                synchronizationId={synchronizationId}
            />
        </>
    );
};
