import React from 'react';
import { useQuery } from 'react-query';
import { Loading } from '../../component';
import { useCurrentStore } from '../MultiStore';
import { testBCConnection } from './api/testBCConnection';
import { useGetBCConfig } from './hook/useGetBCConfig';
import { useGetConnectorConfig } from './hook/useGetConnectorConfig';
import { ConnectionStatus } from './model/ConnectionStatus';

type Props = {
    children: React.ReactNode;
    fallback: (
        isBCCredentialsInvalid: boolean,
        isConfigInvalid: boolean,
        isBcConfigInvalid: boolean
    ) => React.ReactNode;
};

export const ValidConfigurationGuard = ({ children, fallback }: Props) => {
    const { currentStoreId } = useCurrentStore();
    const testBCCredentialsQuery = useQuery(
        [currentStoreId, 'testBCConnection'],
        () => testBCConnection(currentStoreId)
    );
    const isBCCredentialsInvalid =
        testBCCredentialsQuery.data !== ConnectionStatus.Succeeded;

    const getConnectorConfigQuery = useGetConnectorConfig(currentStoreId);
    const getBcConfigQuery = useGetBCConfig(currentStoreId);
    const isConfigInvalid = !getConnectorConfigQuery.data;
    const isBcConfigInvalid = !getBcConfigQuery.data?.storefrontId;

    if (
        testBCCredentialsQuery.isLoading ||
        getConnectorConfigQuery.isLoading ||
        getBcConfigQuery.isLoading
    ) {
        return <Loading />;
    }

    if (isBCCredentialsInvalid || isConfigInvalid || isBcConfigInvalid) {
        return (
            <>
                {fallback(
                    isBCCredentialsInvalid,
                    isConfigInvalid,
                    isBcConfigInvalid
                )}
            </>
        );
    }

    return <>{children}</>;
};
