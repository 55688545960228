import { Helper, Link, SectionTitle, Table } from 'akeneo-design-system';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import { Loading, SpacerSmall } from '../../../../component';
import { usePimAssetCollectionAttributes } from '../hook/usePimAssetCollectionAttributes';
import { AssetAttributeItem } from './AssetAttributeItem';
import { helpCenterHowToMapMediaUrl } from '../../../../util/config';

const Space = styled(Table.HeaderCell)`
    width: 100%;
`;

type Props = {
    value:
        | { attributeCode: null }
        | { attributeCode: string; assetAttributeCode: null | string };
    onChange: (
        value:
            | { attributeCode: null }
            | { attributeCode: string; assetAttributeCode: null | string }
    ) => void;
};

export const SelectAssetAttributeSection = ({ value, onChange }: Props) => {
    const { isLoading, attributes } = usePimAssetCollectionAttributes();

    const handleChange = (
        value:
            | { attributeCode: null }
            | { attributeCode: string; assetAttributeCode: null | string }
    ) => onChange(value);

    return (
        <>
            <SectionTitle>
                <SectionTitle.Title>
                    <FormattedMessage
                        defaultMessage='Add asset collection'
                        id='qaiIu8'
                    />
                </SectionTitle.Title>
            </SectionTitle>
            <Helper level='info'>
                <FormattedMessage
                    id='FAAVGy'
                    defaultMessage='The App supports one asset collection to synchronize and map one asset attribute.'
                />{' '}
                <Link href={helpCenterHowToMapMediaUrl} target='_blank'>
                    <FormattedMessage
                        id='i/qJsQ'
                        defaultMessage='Please check the Help Center for more information.'
                    />
                </Link>
            </Helper>
            <SpacerSmall />
            {isLoading ? (
                <Loading />
            ) : (
                <Table>
                    <Table.Header>
                        <Table.HeaderCell>
                            <FormattedMessage
                                id='0mJgQP'
                                defaultMessage='Asset collection'
                            />
                        </Table.HeaderCell>
                        <Table.HeaderCell>
                            <FormattedMessage
                                id='IqlC43'
                                defaultMessage='Asset attribute'
                            />
                        </Table.HeaderCell>
                        <Table.HeaderCell>
                            <FormattedMessage
                                id='KjJVAm'
                                defaultMessage='Asset attribute type
                            '
                            />
                        </Table.HeaderCell>
                        <Table.HeaderCell>
                            <FormattedMessage
                                id='4zRIAo'
                                defaultMessage='Media type
                            '
                            />
                        </Table.HeaderCell>
                        <Space />
                    </Table.Header>
                    <Table.Body>
                        <Table.Row>
                            <AssetAttributeItem
                                attributes={attributes}
                                value={value}
                                onChange={handleChange}
                            />
                        </Table.Row>
                    </Table.Body>
                </Table>
            )}
        </>
    );
};
