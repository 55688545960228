import { FormattedMessage } from 'react-intl';

type Props = {
    isScheduledSync: boolean;
};

export const SynchronizationScheduledManualLabel = ({
    isScheduledSync,
}: Props) => {
    return isScheduledSync ? (
        <FormattedMessage defaultMessage='Scheduled sync' id='VfB4FS' />
    ) : (
        <FormattedMessage defaultMessage='Manual sync' id='zFFdYx' />
    );
};
