import { apiFetch } from '../../../util/apiFetch';

type Result = {
    data: {
        categoryCode: string;
        locale: string;
        channel: string;
        currency: string;
        pre_existing_catalog_mapping?: {
            product_mapping: {
                bigcommerce_product_identifier: string | null;
                pim_product_identifier: string | null;
            };
            variant_mapping: {
                bigcommerce_variant_identifier: string | null;
                pim_variant_identifier: string | null;
            };
        };
    } | null;
};

export const getConnectorConfig = (storeId: string) =>
    apiFetch<Result>(`${storeId}/configuration/retrieveConfig`).then(
        (result) => result.data
    );
