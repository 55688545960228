import { ConnectionStatus } from '../model/ConnectionStatus';
import { apiFetch } from '../../../util/apiFetch';

export const testBCConnection = async (storeId: string) => {
    const rateLimitingReached = 'rate_limiting_reached';
    const instabilitySpotted = 'instability_spotted';
    const success = 'success';
    const invalidCredentials = 'invalid_credentials';
    const missingApiScopes = 'missing_api_scopes';

    return apiFetch<{ result: string | null }>(
        `${storeId}/configuration/testBcConnection`
    ).then((data) => {
        if (success === data.result) {
            return ConnectionStatus.Succeeded;
        }
        if (
            rateLimitingReached === data.result ||
            instabilitySpotted === data.result
        ) {
            return ConnectionStatus.Failed;
        }

        if (invalidCredentials === data.result) {
            return ConnectionStatus.InvalidCredentials;
        }

        if (missingApiScopes === data.result) {
            return ConnectionStatus.MissingApiScopes;
        }

        return ConnectionStatus.None;
    });
};
