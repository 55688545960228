import { Checkbox, Field, Helper, SelectInput } from 'akeneo-design-system';
import { FormattedMessage, useIntl } from 'react-intl';
import { DateTimeInput } from '../../../../component';
import { useDispatch, useSelector } from '../../store/StoreProvider';
import styled from 'styled-components';
import { SynchronizationScope } from '../../model/SynchronizationScope';
import { CompletenessFilterInput } from './CompletenessFilterInput';
import { FilterPimAttribute } from '../../model/FilterPimAttribute';

const ModalContent = styled.div<{ scope: SynchronizationScope }>`
    min-height: ${({ scope }) =>
        scope === SynchronizationScope.Product ? '420px' : '230px'};
`;

const StyledHelper = styled(Helper)`
    font-size: 11px;
`;

const CheckboxLabelContainer = styled.div`
    font-size: ${({ theme }) => theme.fontSize.default};
`;

const CheckboxHelper = styled(Helper)`
    font-size: 11px;
    margin-top: 5px;
`;

type Props = {
    scope: SynchronizationScope;
    minCompleteness: number | null;
    setMinCompleteness: (value: number) => void;
    priceImport: boolean;
    setPriceImport: (value: boolean) => void;
    withCustomFields: boolean;
    setCustomFieldsFilter: (value: boolean) => void;
    withImages: boolean;
    setImagesFilter: (value: boolean) => void;
    withImportPreExistingProductsOnly: boolean;
    setImportPreExistingProductsOnlyFilter: (value: boolean) => void;
    filterAttribute: string | null;
    setFilterAttribute: (value: string | null) => void;
    filterPimAttributes: FilterPimAttribute[];
    isPreExistingCatalogMappingConfigured: boolean;
    isProductMediaMappingEmpty: boolean;
    isCustomFieldMappingEmpty: boolean;
};

export const FiltersForm = ({
    scope,
    minCompleteness,
    setMinCompleteness,
    priceImport,
    setPriceImport,
    withCustomFields,
    setCustomFieldsFilter,
    withImages,
    setImagesFilter,
    withImportPreExistingProductsOnly,
    setImportPreExistingProductsOnlyFilter,
    filterAttribute,
    setFilterAttribute,
    filterPimAttributes,
    isPreExistingCatalogMappingConfigured,
    isProductMediaMappingEmpty,
    isCustomFieldMappingEmpty,
}: Props) => {
    const intl = useIntl();
    const dispatch = useDispatch();
    const filters = useSelector((state) => state.filters);

    const handleMethodChange = (method: string) =>
        dispatch({
            type: 'filters/changed',
            payload: {
                ...filters,
                method: method as 'FULL' | 'DIFFERENTIAL',
            },
        });

    const handleDateTimeChange = (updated: string) =>
        dispatch({
            type: 'filters/changed',
            payload: {
                ...filters,
                updated,
            },
        });

    const handleCompletenessChange = (value: string) => {
        setMinCompleteness(parseInt(value));
    };

    const handlePriceImportChange = (value: boolean) => {
        setPriceImport(value);
    };
    const handleImagesFilterChange = (value: boolean) => {
        setImagesFilter(value);
    };

    const handleCustomFieldsFilterChange = (value: boolean) => {
        setCustomFieldsFilter(value);
    };

    const handleImportPreExistingProductsOnlyFilterChange = (
        value: boolean
    ) => {
        setImportPreExistingProductsOnlyFilter(value);
    };

    return (
        <ModalContent scope={scope}>
            <Helper level='info'>
                <FormattedMessage
                    id='Zaj5n6'
                    defaultMessage='If you choose the full import, the whole catalog will be imported. For the first import, please use full mode only.'
                />
            </Helper>
            <br />
            <Field
                label={intl.formatMessage({
                    id: 'MMGQKE',
                    defaultMessage: 'Import process (required)',
                })}
            >
                <SelectInput
                    value={filters.method}
                    onChange={handleMethodChange}
                    clearable={false}
                    emptyResultLabel=''
                    openLabel=''
                >
                    <SelectInput.Option value='DIFFERENTIAL'>
                        <FormattedMessage id='kDNmhM' defaultMessage='Delta' />
                    </SelectInput.Option>

                    <SelectInput.Option value='FULL'>
                        <FormattedMessage id='NYnkyd' defaultMessage='Full' />
                    </SelectInput.Option>
                </SelectInput>
            </Field>
            {filters.method === 'DIFFERENTIAL' && (
                <>
                    <br />
                    <div>
                        <Field
                            label={intl.formatMessage({
                                id: 'UL9OEs',
                                defaultMessage: 'Select the reference date',
                            })}
                        >
                            <DateTimeInput
                                dateTime={filters.updated}
                                onChange={handleDateTimeChange}
                            />
                        </Field>
                    </div>
                </>
            )}
            {scope === SynchronizationScope.Product && (
                <>
                    <br />
                    <Field
                        label={intl.formatMessage({
                            id: 'aJbRki',
                            defaultMessage: 'Completeness',
                        })}
                    >
                        <CompletenessFilterInput
                            onChange={handleCompletenessChange}
                            minCompleteness={minCompleteness}
                        />
                        <StyledHelper level='info' inline>
                            <FormattedMessage
                                id='IExcXx'
                                defaultMessage='Apply this filter to import or update the products by their completeness.'
                            />
                        </StyledHelper>
                    </Field>
                </>
            )}
            {(scope === SynchronizationScope.Product ||
                scope === SynchronizationScope.ProductVariant) && (
                <>
                    <br />
                    <Field
                        label={intl.formatMessage({
                            defaultMessage: 'Filter attribute',
                            id: '+fhF3Y',
                        })}
                    >
                        <SelectInput
                            value={filterAttribute}
                            onChange={setFilterAttribute}
                            clearable={true}
                            emptyResultLabel=''
                            openLabel=''
                        >
                            {filterPimAttributes.map((option) => (
                                <SelectInput.Option
                                    key={option.code}
                                    value={option.code}
                                >
                                    {option.label}
                                </SelectInput.Option>
                            ))}
                        </SelectInput>
                    </Field>
                    <StyledHelper level='info' inline>
                        <FormattedMessage
                            defaultMessage='Please select a PIM attribute that will be used to filter the products to be synchronized.'
                            id='dodKbD'
                        />
                    </StyledHelper>
                    <br />
                    {!isProductMediaMappingEmpty && (
                        <>
                            <Checkbox
                                checked={withImages}
                                onChange={handleImagesFilterChange}
                            >
                                <CheckboxLabelContainer>
                                    <FormattedMessage
                                        id='xRxTHW'
                                        defaultMessage='Import images'
                                    />
                                </CheckboxLabelContainer>
                            </Checkbox>
                            <br />
                        </>
                    )}
                    {!isCustomFieldMappingEmpty && (
                        <>
                            <Checkbox
                                checked={withCustomFields}
                                onChange={handleCustomFieldsFilterChange}
                            >
                                <CheckboxLabelContainer>
                                    <FormattedMessage
                                        id='hMMrUQ'
                                        defaultMessage='Import custom fields'
                                    />
                                </CheckboxLabelContainer>
                            </Checkbox>
                            <br />
                        </>
                    )}
                    <Checkbox
                        checked={priceImport}
                        onChange={handlePriceImportChange}
                    >
                        <CheckboxLabelContainer>
                            <FormattedMessage
                                id='y2LOM0'
                                defaultMessage='Import prices'
                            />
                        </CheckboxLabelContainer>
                    </Checkbox>

                    {isPreExistingCatalogMappingConfigured && (
                        <>
                            <br />
                            <Checkbox
                                checked={withImportPreExistingProductsOnly}
                                onChange={
                                    handleImportPreExistingProductsOnlyFilterChange
                                }
                            >
                                <CheckboxLabelContainer>
                                    <FormattedMessage
                                        defaultMessage='Import pre-existing products only'
                                        id='Gm34kQ'
                                    />
                                </CheckboxLabelContainer>
                            </Checkbox>
                            <CheckboxHelper level='info' inline>
                                <FormattedMessage
                                    defaultMessage='Apply this filter to synchronize only PIM products with pre-existing BigCommerce products.'
                                    id='kk8fHt'
                                />
                            </CheckboxHelper>
                            <br />
                        </>
                    )}
                </>
            )}
        </ModalContent>
    );
};
