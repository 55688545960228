import { ValidConfigurationGuard } from '../Configuration';
import { InvalidConfiguration } from './component/InvalidConfiguration';
import { Synchronization } from './SynchronizationTab/Synchronization';
import { HistoryTab } from './HistoryTab/HistoryTab';
import { initialState } from './store/reducer';
import { StoreProvider } from './store/StoreProvider';
import { Route, Routes } from 'react-router-dom';
import { SynchronizationReport } from '../SynchronizationReport';
import { DetailedModalReport } from '../SynchronizationReport/DetailedModalReport/DetailedModalReport';

export const SynchronizationTab = () => {
    return (
        <ValidConfigurationGuard fallback={() => <InvalidConfiguration />}>
            <StoreProvider initialState={initialState}>
                <Routes>
                    <Route
                        path='/'
                        element={
                            <>
                                <Synchronization />
                                <HistoryTab />
                            </>
                        }
                    />
                    <Route
                        path='synchronization-report/:synchronization'
                        element={<SynchronizationReport />}
                    />
                    <Route
                        path='synchronization-report/:synchronization/detailed-logs'
                        element={<DetailedModalReport />}
                    />
                </Routes>
            </StoreProvider>
        </ValidConfigurationGuard>
    );
};
