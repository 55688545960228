import { apiFetch } from '../../../util/apiFetch';

type Result = {
    data: Array<Store>;
};
export type Store = {
    id: string;
    hash: string;
    name: string;
    status: string;
    lastSynchronizationImport: string | null;
    lastSynchronizationStatus: string | null;
};

export const getStores = () =>
    apiFetch<Result>('stores').then((result) => {
        return result.data;
    });
