import { apiFetch } from '../../../util/apiFetch';

type Result = {
    bcAttributes: {
        [code: string]: {
            code: string;
            collection: boolean;
            type: string;
            label: string;
            constraint: string;
            pimAllowedTypes: string[];
            isRequired: boolean;
        };
    };
    bcAttributeSections: {
        [label: string]: string[];
    };
};

export const getBCAttributes = (storeId: string) =>
    apiFetch<Result>(`${storeId}/mapping/bc-attributes`).then(
        (result) => result
    );
