import { apiFetch } from '../../../util/apiFetch';

type customFieldSelectionByStore = {
    customFieldSelection: string[];
    storeId: string;
};
export const saveCustomFields = (
    customFieldSelection: customFieldSelectionByStore
) => {
    return apiFetch(
        `${customFieldSelection.storeId}/mapping/saveCustomFields`,
        {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(customFieldSelection.customFieldSelection),
        }
    );
};
