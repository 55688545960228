import { apiFetch } from '../../../util/apiFetch';
import { ProductVariantsMapping } from '../ProductVariant/model/ProductVariantsMapping';
import { Immutable } from 'immer';
import { VariantAttributeMapping } from '../ProductVariant/model/VariantAttributeMapping';

export const saveProductVariantMapping = async (
    productVariantMapping: Immutable<ProductVariantsMapping>,
    storeId: string
) => {
    let data: { [bcAttributeCode: string]: VariantAttributeMapping } = {};

    productVariantMapping.forEach(
        (variantAttributeMapping: VariantAttributeMapping) => {
            data[variantAttributeMapping.bcAttributeCode] =
                variantAttributeMapping;
        }
    );

    await apiFetch(`${storeId}/mapping/saveProductVariantMapping`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
    });
};
