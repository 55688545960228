import { useState } from 'react';
import { useQuery } from 'react-query';
import { useCurrentStore } from '../../../MultiStore';
import { MediaSource } from '../../model/MediaSource';
import { fetchMediaMapping } from '../api/fetchMediaMapping';
import { MediaMapping } from '../model/MediaMapping';

export const fetchMediaMappingQueryKey = 'fetchProductVariantMediaMapping';

export const useMediaMapping = () => {
    const { currentStoreId } = useCurrentStore();

    const [mapping, setMapping] = useState<MediaMapping>({
        source: MediaSource.Images,
        attributeCode: null,
    });

    useQuery(
        fetchMediaMappingQueryKey,
        () => fetchMediaMapping(currentStoreId),
        { onSuccess: setMapping }
    );

    return { mapping, setMapping };
};
