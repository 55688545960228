import {
    Button,
    getFontSize,
    Modal,
    DeleteFamilyVariantIllustration,
} from 'akeneo-design-system';
import React, { useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import styled from 'styled-components';

const Description = styled.div`
    font-size: ${getFontSize('bigger')};
`;

type Props = {
    onConfirm: () => Promise<void>;
    onCancel: () => void;
};

export const DeleteStoreConfirmationModal = ({
    onConfirm,
    onCancel,
}: Props) => {
    const intl = useIntl();

    const [disableButtons, setDisableButtons] = useState(false);

    const handleConfirm = async () => {
        setDisableButtons(true);
        await onConfirm();
        setDisableButtons(false);
    };

    return (
        <Modal
            closeTitle={intl.formatMessage({
                id: '47FYwb',
                defaultMessage: 'Cancel',
            })}
            onClose={() => !disableButtons && onCancel()}
            illustration={<DeleteFamilyVariantIllustration />}
        >
            <Modal.Title>
                <FormattedMessage id='uMUgE6' defaultMessage='Delete Store' />
            </Modal.Title>
            <Description>
                <FormattedMessage
                    id='G3qG+n'
                    defaultMessage='You are about to delete your store.'
                />
                <br />
                <FormattedMessage
                    id='aw74V2'
                    defaultMessage='Please note that all data related to the store will be deleted:'
                />
                <ul>
                    <li>
                        <FormattedMessage
                            id='7OW8BT'
                            defaultMessage='Configuration'
                        />
                    </li>
                    <li>
                        <FormattedMessage
                            id='GmyYfu'
                            defaultMessage='Mapping'
                        />
                    </li>
                    <li>
                        <FormattedMessage
                            id='lBjsoO'
                            defaultMessage='Synchronization history'
                        />
                    </li>
                </ul>
            </Description>

            <Modal.BottomButtons>
                <Button
                    level='tertiary'
                    onClick={onCancel}
                    disabled={disableButtons}
                >
                    <FormattedMessage id='47FYwb' defaultMessage='Cancel' />
                </Button>
                <Button
                    level='danger'
                    onClick={handleConfirm}
                    disabled={disableButtons}
                >
                    <FormattedMessage id='K3r6DQ' defaultMessage='Delete' />
                </Button>
            </Modal.BottomButtons>
        </Modal>
    );
};
