import { apiFetch } from '../../../util/apiFetch';

type Result = {
    [code: string]: {
        code: string;
        label: string;
    };
    // @ts-ignore
    error?: string;
};

export const getPimFamilies = (storeId: string) =>
    apiFetch<Result>(`${storeId}/mapping/getPimFamilies`);
