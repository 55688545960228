import { Helper, Link, SectionTitle } from 'akeneo-design-system';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { ImageAttributeList } from './ImageAttributeList';
import { SpacerSmall } from '../../../../../component/Spacer';
import { helpCenterHowToMapMediaUrl } from '../../../../../util/config';

const MAX_IMAGES_ATTRIBUTES = 15;

export const ImageAttributes = () => {
    return (
        <>
            <SectionTitle>
                <SectionTitle.Title>
                    <FormattedMessage
                        id='d/Ye6S'
                        defaultMessage='Add image attributes'
                    />
                </SectionTitle.Title>
            </SectionTitle>

            <Helper level='info'>
                <FormattedMessage
                    id='LW17R1'
                    values={{
                        maxImagesAttributes: MAX_IMAGES_ATTRIBUTES,
                    }}
                    defaultMessage='The App supports up to {maxImagesAttributes} images per product. You can sort your images.'
                />{' '}
                <Link href={helpCenterHowToMapMediaUrl} target='_blank'>
                    <FormattedMessage
                        id='i/qJsQ'
                        defaultMessage='Please check the Help Center for more information.'
                    />
                </Link>
            </Helper>
            <SpacerSmall />
            <ImageAttributeList maxAttribute={MAX_IMAGES_ATTRIBUTES} />
        </>
    );
};
