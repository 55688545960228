import styled from 'styled-components';
import React, { ReactNode } from 'react';
import { SpacerBig, SpacerSmall } from './Spacer';

const Container = styled.div`
    background: white;
    left: 0;
    padding: 40px 80px;
    position: absolute;
    top: 0;
    width: 100%;
    min-height: 100%;
`;

const TopRightContainer = styled.div`
    position: absolute;
    right: 80px;
    top: 40px;
`;

const Title = styled.div`
    color: ${({ theme }) => theme.color.brand100};
    font-size: ${({ theme }) => theme.fontSize.default};
    font-weight: normal;
    text-align: center;
    text-transform: uppercase;
`;

const SubTitle = styled.div`
    color: ${({ theme }) => theme.color.grey140};
    font-size: ${({ theme }) => theme.fontSize.title};
    display: flex;
    justify-content: center;
    align-items: center;
    height: 34px;
`;

type CustomModalProps = {
    title: string;
    subTitle: string;
    actions: ReactNode;
    children: ReactNode;
};

export const CustomModal = ({
    title,
    subTitle,
    actions,
    children,
}: CustomModalProps) => {
    return (
        <Container>
            <TopRightContainer>{actions}</TopRightContainer>

            <Title>{title}</Title>

            <SpacerSmall></SpacerSmall>

            <SubTitle>{subTitle}</SubTitle>

            <SpacerBig></SpacerBig>

            {children}
        </Container>
    );
};
