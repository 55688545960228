import { Badge } from 'akeneo-design-system';
import { FormattedMessage } from 'react-intl';

type Props = {
    status: 'complete' | 'not_complete';
};

export const MappingState = ({ status }: Props) => {
    if ('complete' === status) {
        return (
            <Badge level='primary'>
                <FormattedMessage id='U78NhE' defaultMessage='Complete' />
            </Badge>
        );
    }

    if ('not_complete' === status) {
        return (
            <Badge level='tertiary'>
                <FormattedMessage id='L9Ueun' defaultMessage='Not Complete' />
            </Badge>
        );
    }

    return null;
};
