import React from 'react';
import { useQuery } from 'react-query';
import { useCurrentStore } from '../../MultiStore';
import { fetchProductVariantsMapping } from '../api/fetchProductVariantsMapping';
import { initialState, reducer } from './store/reducer';
import { DispatchContext, StateContext } from './store/StoreProvider';

type Props = {
    children: React.ReactNode;
};

export const ProductVariantMappingProvider = ({ children }: Props) => {
    const [state, dispatch] = React.useReducer(reducer, initialState);

    const { currentStoreId } = useCurrentStore();
    const { isFetching } = useQuery(
        [currentStoreId, 'fetchProductVariantsMapping'],
        () => fetchProductVariantsMapping(currentStoreId),
        {
            onSuccess: (productVariantMapping) =>
                dispatch({
                    type: 'fetchProductVariantsMapping/fulfilled',
                    productVariantMapping: productVariantMapping,
                }),
        }
    );

    if (isFetching) {
        return <></>;
    }

    return (
        <StateContext.Provider value={state}>
            <DispatchContext.Provider value={dispatch}>
                {children}
            </DispatchContext.Provider>
        </StateContext.Provider>
    );
};
