import { apiFetch } from '../../../util/apiFetch';

type Result = {
    locale: { [code: string]: string };
    channel: { [code: string]: string };
    currency: { [code: string]: string };
    error?: string;
};

export const getConnectorConfigOptions = (storeId: string): Promise<Result> =>
    apiFetch<Result>(`${storeId}/configuration/retrieveConfigOptions`);
