import { useState } from 'react';
import { useIntl } from 'react-intl';
import { useQueryClient } from 'react-query';
import { useToaster } from '../../../util/toast';
import { useSelector } from '../store/StoreProvider';
import { useSaveBCCredentialsForm } from './useSaveBCCredentialsForm';
import { useSaveConnectorConfigForm } from './useSaveConnectorConfigForm';
import { useSaveBcConfigForm } from './useSaveBcConfigForm';
import { useCurrentStore } from '../../MultiStore';

export const useSaveConfiguration = () => {
    const intl = useIntl();
    const addToast = useToaster();
    const queryClient = useQueryClient();

    const { currentStoreId } = useCurrentStore();
    const state = useSelector((state) => state);

    const saveBCCredentialsForm = useSaveBCCredentialsForm();
    const saveConnectorConfigForm = useSaveConnectorConfigForm();
    const saveBcConfigForm = useSaveBcConfigForm();

    const [isSaving, setIsSaving] = useState(false);

    const save = async () => {
        setIsSaving(true);

        const saveResults = await Promise.allSettled([
            saveBCCredentialsForm(state.bcCredentialsForm),
            saveConnectorConfigForm(state.connectorConfigForm),
            saveBcConfigForm(state.connectorBcConfigForm),
        ]);
        queryClient.invalidateQueries([currentStoreId, 'getConnectorConfig']);

        const saveFulfilledResults = saveResults.filter(
            (result): result is PromiseFulfilledResult<boolean> => {
                if (result.status === 'rejected') {
                    throw new Error(result.reason);
                }

                return true;
            }
        );

        if (saveFulfilledResults.find((result) => true === result.value)) {
            addToast(
                intl.formatMessage({
                    id: 'CQl2wT',
                    defaultMessage: 'Configuration successfully updated.',
                }),
                'success'
            );
        }

        setIsSaving(false);
    };

    return { save, isSaving };
};
