import { apiFetch } from '../../../../util/apiFetch';
import { ReportLogModel } from '../model/SynchronizationReportLogsModel';

export const getSynchronizationReportLogs = (
    storeId: string,
    synchronizationId: string,
    logCode: string
) =>
    apiFetch<ReportLogModel>(
        `${storeId}/synchronization/${synchronizationId}/retrieve-synchronization-report/${logCode}/report-log`
    ).then((result) => result);
