import { Helper, Link, SectionTitle } from 'akeneo-design-system';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { SpacerSmall } from '../../../../../component/Spacer';
import { AssetAttributeList } from './AssetAttributeList';
import { helpCenterHowToMapMediaUrl } from '../../../../../util/config';

const MAX_ASSETS_ATTRIBUTES = 5;

export const AssetAttributes = () => {
    return (
        <>
            <SectionTitle>
                <SectionTitle.Title>
                    <FormattedMessage
                        id='aBkv9+'
                        defaultMessage='Add asset collections'
                    />
                </SectionTitle.Title>
            </SectionTitle>

            <Helper level='info'>
                <FormattedMessage
                    id='EJUmsT'
                    values={{
                        maxAssetsAttributes: MAX_ASSETS_ATTRIBUTES,
                    }}
                    defaultMessage='The App supports up to {maxAssetsAttributes} asset collections to synchronize and map one asset attribute for each.'
                />{' '}
                <Link href={helpCenterHowToMapMediaUrl} target='_blank'>
                    <FormattedMessage
                        id='i/qJsQ'
                        defaultMessage='Please check the Help Center for more information.'
                    />
                </Link>
            </Helper>
            <SpacerSmall></SpacerSmall>
            <AssetAttributeList maxAttribute={MAX_ASSETS_ATTRIBUTES} />
        </>
    );
};
