import { useContext } from 'react';
import { useQuery } from 'react-query';
import { CurrentPimVersionContext } from './CurrentPimVersionContext';
import { getPimVersion } from './getPimVersion';

type Props = {
    children: React.ReactNode;
    onPimVersionNotFound: () => void;
};

export const PimVersionRouteGuard = ({
    children,
    onPimVersionNotFound,
}: Props) => {
    const context = useContext(CurrentPimVersionContext);
    if (null === context) {
        throw new Error('<CurrentPimVersionProvider /> not setup');
    }

    useQuery('retrievePimVersion', getPimVersion, {
        onSuccess: (data) => {
            if (null === data) {
                onPimVersionNotFound();
            } else {
                context.setCurrentPimVersion(data);
            }
        },
    });

    if (null === context.currentPimVersion) {
        return <></>;
    }

    return <>{children}</>;
};
