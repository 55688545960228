export const helpCenterUrl =
    'https://help.akeneo.com/en_US/akeneo-app-for-bigcommerce';
export const helpCenterStoreListUrl =
    'https://help.akeneo.com/bigcommerce-app-features/bigcommerce-app-multistore-management';
export const helpCenterCreateStoreUrl =
    'https://help.akeneo.com/en_US/bigcommerce-app-features/bigcommerce-app-multistore-management#create-a-new-store';
export const helpCenterLogsUrl =
    'https://help.akeneo.com/bigcommerce-app-features/bigcommerce-app-logs-errors';
export const helpCenterSetUpStoreConfigurationUrl =
    'https://help.akeneo.com/bigcommerce-app-features/bigcommerce-app-configuration-set-up#setting-up-store-configuration';
export const helpCenterSetUpBigcommerceCredentialsUrl =
    'https://help.akeneo.com/bigcommerce-app-features/bigcommerce-app-configuration-set-up#create-a-bigcommerce-api-account';
export const helpCenterSetUpDisplayTypeForVariantsUrl =
    'https://help.akeneo.com/bigcommerce-app-features/bigcommerce-app-configuration-set-up#set-up-display-type-for-variants';
export const helpCenterMediaMappingUrl =
    'https://help.akeneo.com/bigcommerce-app-features/bigcommerce-app-media-mapping#media-mapping';
export const helpCenterHowToMapMediaUrl =
    'https://help.akeneo.com/bigcommerce-app-features/bigcommerce-app-media-mapping#how-to-map-media';

export const helpCenterCustomFieldsMappingUrl =
    'https://help.akeneo.com/bigcommerce-app-features/bigcommerce-app-custom-field-mapping';

export const helpCenterPreExistingCatalog =
    'https://help.akeneo.com/bigcommerce-app-features/bigcommerce-app-pre-existing-catalogs';

export const supportUrl =
    'https://akeneo.atlassian.net/servicedesk/customer/portal/8/group/28/create';
