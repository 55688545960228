import { Button } from 'akeneo-design-system';
import { FormattedMessage } from 'react-intl';
import { useNavigate } from 'react-router-dom';

export const ManageCustomFieldsButton = () => {
    const navigate = useNavigate();

    return (
        <Button level='primary' onClick={() => navigate('selection')}>
            <FormattedMessage
                id='JGGcnG'
                defaultMessage='Manage custom fields'
            />
        </Button>
    );
};
