import { getColor, getFontSize } from 'akeneo-design-system';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useQuery } from 'react-query';
import styled from 'styled-components';
import { apiFetch } from '../util/apiFetch';
import { ReturnIcon } from './ReturnIcon';

const Container = styled.div`
    margin: 0 0 0 auto;
`;

const Link = styled.a`
    color: ${getColor('grey', 120)};
    font-size: ${getFontSize('big')};
    text-decoration: none;
    margin-right: 5px;
`;

export const GoToPimLink = () => {
    const { isSuccess, data } = useQuery('retrievePimUrl', () =>
        apiFetch<{ pim_url: string }>('retrievePimUrl')
    );

    if (!isSuccess) {
        return null;
    }

    return (
        <Container>
            <Link href={data.pim_url} target={'_blank'}>
                <FormattedMessage
                    id='gZmiLL'
                    defaultMessage='Go to Akeneo PIM'
                />
            </Link>
            <ReturnIcon />
        </Container>
    );
};
