import { Helper, SectionTitle } from 'akeneo-design-system';
import { FormattedMessage } from 'react-intl';
import { useMutation, useQuery } from 'react-query';
import { Loading } from '../../../component';
import { useCurrentStore } from '../../MultiStore';
import { fetchSynchronizations } from '../api/fetchSynchronizations';
import { launchSynchronization } from '../api/launchSynchronization';
import { SynchronizationScope } from '../model/SynchronizationScope';
import { useDispatch, useSelector } from '../store/StoreProvider';
import { SynchronizationItem } from './component/SynchronizationItem';
import { SynchronizationList } from './component/SynchronizationList';
import { useNotifyFinishedSynchronization } from './hooks/useNotifyFinishedSynchronization';
import { stopSynchronization } from '../api/stopSynchronization';
import { saveScheduledSyncConfig } from '../api/saveScheduledSyncConfig';
import { fetchFilterPimAttributes } from '../api/fetchFilterPimAttributes';
import { useState } from 'react';
import { FilterPimAttribute } from '../model/FilterPimAttribute';
import { isProductPreExistingCatalogMappingConfigured } from '../api/IsProductPreExistingCatalogMappingConfigured';
import { isVariantPreExistingCatalogMappingConfigured } from '../api/IsVariantPreExistingCatalogMappingConfigured';
import { isProductMediaMappingEmpty } from '../api/IsProductMediaMappingEmpty';
import { isProductWithVariantsMediaMappingEmpty } from '../api/IsProductWithVariantsMediaMappingEmpty';
import { isCustomFieldMappingEmpty as isCustomFieldMappingEmptyApi } from '../api/IsCustomFieldMappingEmpty';
import { dateTimeToIso8601 } from '../../../util/dateTimeToIso8601';
import { DateTime } from 'luxon';

export const Synchronization = () => {
    const dispatch = useDispatch();
    const { filters, synchronizations } = useSelector((state) => state);
    const [filterPimAttributes, setFilterPimAttributes] = useState<
        FilterPimAttribute[]
    >([]);

    const [
        productPreExistingCatalogMappingConfigured,
        setProductPreExistingCatalogMappingConfigured,
    ] = useState(false);

    const [
        variantPreExistingCatalogMappingConfigured,
        setVariantPreExistingCatalogMappingConfigured,
    ] = useState(false);

    const [
        isSimpleProductMediaMappingEmpty,
        seIsSimpleProductMediaMappingEmpty,
    ] = useState(false);

    const [
        isProductVariantsMediaMappingEmpty,
        seIsProductVariantsMediaMappingEmpty,
    ] = useState(false);

    const [isCustomFieldMappingEmpty, seIsCustomFieldMappingEmpty] =
        useState(false);

    const { currentStoreId } = useCurrentStore();
    const stopSynchronizationMutation = useMutation(stopSynchronization);
    const launchSynchronizationMutation = useMutation(launchSynchronization);

    useNotifyFinishedSynchronization();

    const { isLoading, refetch } = useQuery(
        [currentStoreId, 'fetchSynchronizations'],
        () => fetchSynchronizations(currentStoreId),
        {
            enabled: !launchSynchronizationMutation.isLoading,
            refetchInterval: 5000,
            onSuccess: (synchronizations) => {
                dispatch({
                    type: 'synchronization/fetchSynchronizations/fulfilled',
                    payload: synchronizations,
                });
            },
        }
    );

    const saveScheduledSyncConfigMutation = useMutation(
        saveScheduledSyncConfig,
        {
            onSuccess: () => {
                refetch();
            },
        }
    );

    useQuery(
        'isProductPreExistingCatalogMappingConfigured',
        () => isProductPreExistingCatalogMappingConfigured(currentStoreId),
        {
            onSuccess: (result) => {
                setProductPreExistingCatalogMappingConfigured(
                    result.isConfigured
                );
            },
        }
    );

    useQuery(
        'isVariantPreExistingCatalogMappingConfigured',
        () => isVariantPreExistingCatalogMappingConfigured(currentStoreId),
        {
            onSuccess: (result) => {
                setVariantPreExistingCatalogMappingConfigured(
                    result.isConfigured
                );
            },
        }
    );

    useQuery(
        'isProductMediaMappingEmpty',
        () => isProductMediaMappingEmpty(currentStoreId),
        {
            onSuccess: (result) => {
                seIsSimpleProductMediaMappingEmpty(result.is_empty);
            },
        }
    );

    useQuery(
        'isProductWithVariantsMediaMappingEmpty',
        () => isProductWithVariantsMediaMappingEmpty(currentStoreId),
        {
            onSuccess: (result) => {
                seIsProductVariantsMediaMappingEmpty(result.is_empty);
            },
        }
    );

    useQuery(
        'isCustomFieldMappingEmpty',
        () => isCustomFieldMappingEmptyApi(currentStoreId),
        {
            onSuccess: (result) => {
                seIsCustomFieldMappingEmpty(result.is_empty);
            },
        }
    );

    const isPreExistingCatalogMappedByScope = (scope: string): boolean => {
        return (
            (scope === SynchronizationScope.Product &&
                productPreExistingCatalogMappingConfigured) ||
            (scope === SynchronizationScope.ProductVariant &&
                variantPreExistingCatalogMappingConfigured)
        );
    };

    const isProductMediaMappingEmptyByScope = (scope: string): boolean => {
        return (
            (scope === SynchronizationScope.Product &&
                isSimpleProductMediaMappingEmpty) ||
            (scope === SynchronizationScope.ProductVariant &&
                isProductVariantsMediaMappingEmpty)
        );
    };

    const handleLaunch = (
        scope: SynchronizationScope,
        minCompleteness: number | null,
        priceImport: boolean,
        withCustomFields: boolean,
        withImages: boolean,
        withImportPreExistingProductsOnly: boolean,
        filterAttribute: string | null
    ) => {
        dispatch({
            type: 'synchronization/launchSynchronization/pending',
            payload: {
                scope: scope,
                createdAt: dateTimeToIso8601(DateTime.utc()),
                referenceDate:
                    filters.method === 'DIFFERENTIAL' ? filters.updated : null,
            },
        });
        launchSynchronizationMutation.mutate({
            scope,
            updated: filters.method === 'DIFFERENTIAL' ? filters.updated : null,
            minCompleteness,
            priceImport,
            withCustomFields: !isCustomFieldMappingEmpty
                ? withCustomFields
                : false,
            withImages: !isProductMediaMappingEmptyByScope(scope)
                ? withImages
                : false,
            withImportPreExistingProductsOnly,
            filterAttribute,
            storeId: currentStoreId,
        });
    };

    const handleStop = (id: string, scope: SynchronizationScope) => {
        dispatch({
            type: 'synchronization/stopSynchronization/pending',
            payload: { scope: scope },
        });
        stopSynchronizationMutation.mutate({
            id,
            currentStoreId,
        });
    };

    const handleScheduleSync = (
        scope: SynchronizationScope,
        frequency: string,
        filterAttribute: string | null,
        priceImport: boolean,
        withCustomFields: boolean,
        withImages: boolean,
        withImportPreExistingProductsOnly: boolean
    ) => {
        saveScheduledSyncConfigMutation.mutate({
            currentStoreId,
            frequency,
            scope,
            filterAttribute,
            priceImport,
            withCustomFields,
            withImages,
            withImportPreExistingProductsOnly,
        });
    };

    useQuery(
        'fetchFilterPimAttributes',
        () => fetchFilterPimAttributes(currentStoreId),
        {
            onSuccess: (data) => {
                setFilterPimAttributes(data);
            },
        }
    );

    if (isLoading) {
        return <Loading />;
    }

    return (
        <>
            <SectionTitle>
                <SectionTitle.Title>
                    <FormattedMessage
                        id='eOsloh'
                        defaultMessage='Synchronization'
                    />
                </SectionTitle.Title>
            </SectionTitle>

            <Helper level='info'>
                <FormattedMessage
                    defaultMessage='Here you can only find in progress synchronizations, for previous ones, go to History. For the first import, do not forget to launch the Categories import first.'
                    id='bc49hF'
                />
            </Helper>

            <SynchronizationList>
                {Object.values(synchronizations)
                    .sort((synchronizationA, synchronizationB) =>
                        synchronizationA.scope <= synchronizationB.scope
                            ? -1
                            : 1
                    )
                    .map((synchronization) => (
                        <SynchronizationItem
                            key={synchronization.scope}
                            synchronization={synchronization}
                            onLaunch={(
                                minCompleteness: number | null,
                                priceImport: boolean,
                                withCustomFields: boolean,
                                withImages: boolean,
                                withImportPreExistingProductsOnly: boolean,
                                filterAttribute: string | null
                            ) =>
                                handleLaunch(
                                    synchronization.scope,
                                    minCompleteness,
                                    priceImport,
                                    withCustomFields,
                                    withImages,
                                    withImportPreExistingProductsOnly,
                                    filterAttribute
                                )
                            }
                            onSchedule={(
                                frequency: string,
                                filterAttribute: string | null,
                                priceImport: boolean,
                                withCustomFields: boolean,
                                withImages: boolean,
                                withImportPreExistingProductsOnly: boolean
                            ) =>
                                handleScheduleSync(
                                    synchronization.scope,
                                    frequency,
                                    filterAttribute,
                                    priceImport,
                                    withCustomFields,
                                    withImages,
                                    withImportPreExistingProductsOnly
                                )
                            }
                            onStop={handleStop}
                            filterPimAttributes={filterPimAttributes}
                            isPreExistingCatalogMappingConfigured={isPreExistingCatalogMappedByScope(
                                synchronization.scope
                            )}
                            isProductMediaMappingEmpty={isProductMediaMappingEmptyByScope(
                                synchronization.scope
                            )}
                            isCustomFieldMappingEmpty={
                                isCustomFieldMappingEmpty
                            }
                        />
                    ))}
            </SynchronizationList>
        </>
    );
};
