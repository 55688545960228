import { useCallback } from 'react';
import { useQuery } from 'react-query';
import { fetchFeatureFlags } from './fetchFeatureFlags';

export const useFeatureFlags = () => {
    const { data, isLoading } = useQuery(
        'fetchFeatureFlags',
        fetchFeatureFlags,
        {
            refetchInterval: 300000, // 5 minutes
            refetchOnWindowFocus: true,
        }
    );

    const isFeatureEnabled = useCallback(
        (feature: string) => {
            if (undefined === data) {
                return false;
            }

            const isEnabled = data[feature];
            if (undefined === isEnabled) {
                console.warn(`Feature flag "${feature}" doesn't exist`);

                return false;
            }

            return isEnabled;
        },
        [data]
    );

    return { isFeatureEnabled, isLoading };
};
