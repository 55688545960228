import { CheckIcon, CloseIcon, LoaderIcon } from 'akeneo-design-system';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import { ConnectionStatus } from '../model/ConnectionStatus';

const Label = styled.div`
    display: flex;
    align-items: center;
    font-size: ${({ theme }) => theme.fontSize.small};
    gap: 4px;
    text-transform: uppercase;
`;

const SucceededLabel = styled(Label)`
    color: ${({ theme }) => theme.color.green100};
`;

const FailedLabel = styled(Label)`
    color: ${({ theme }) => theme.color.red100};
`;

type Props = {
    status: ConnectionStatus;
};

export const ConnectionStatusLabel = ({ status }: Props) => {
    if (status === ConnectionStatus.Succeeded) {
        return (
            <>
                <SucceededLabel>
                    <CheckIcon size={18} />
                    <FormattedMessage
                        id='zVnW5I'
                        defaultMessage='Connection completed'
                    />
                </SucceededLabel>
            </>
        );
    }

    if (status === ConnectionStatus.Failed) {
        return (
            <FailedLabel>
                <CloseIcon size={18} />
                <FormattedMessage
                    id='5cFOSr'
                    defaultMessage='Connection failed'
                />
            </FailedLabel>
        );
    }

    if (status === ConnectionStatus.Testing) {
        return (
            <Label>
                <LoaderIcon size={18} />
                <FormattedMessage
                    id='CvuiN9'
                    defaultMessage='Testing connection'
                />
            </Label>
        );
    }

    return null;
};
