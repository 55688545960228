import { SectionTitle, Table } from 'akeneo-design-system';
import React from 'react';
import styled from 'styled-components';

type Props = {
    sectionLabel: string;
};

const BCSectionContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
`;

const cellStyle = {
    background: '#f6f7fb',
};

const sectionTitleStyle = {
    border: 'none',
};

const sectionTitleTitleStyle = {
    color: '#4f5bca',
    fontSize: '13px',
    fontFamily: 'Lato',
    fontStyle: 'normal',
    fontWeight: '600',
    lineHeight: '16px',
    padding: '20px 0px 20px 20px',
};

export const MappingSection = ({ sectionLabel }: Props) => {
    return (
        <Table.Row>
            <Table.Cell colSpan={4} style={cellStyle}>
                <BCSectionContainer>
                    <SectionTitle style={sectionTitleStyle}>
                        <SectionTitle.Title
                            level='secondary'
                            style={sectionTitleTitleStyle}
                        >
                            {sectionLabel}
                        </SectionTitle.Title>
                        <SectionTitle.Spacer />
                    </SectionTitle>
                </BCSectionContainer>
            </Table.Cell>
        </Table.Row>
    );
};
