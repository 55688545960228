import { castDraft, Immutable, produce } from 'immer';
import { AttributeMapping } from '../../model/AttributeMapping';
import { BCAttribute } from '../../model/BCAttribute';
import { MappingStatus } from '../../model/MappingStatus';

type BCAttributeMap = { [bcAttributeCode: string]: BCAttribute };
type BCSectionMap = { [sbcSectionLabel: string]: string[] };

type PimAttributeMap = {
    [pimAttributeCode: string]: {
        code: string;
        type: string;
        label: string;
    };
};
type PimFamilyMap = {
    [pimFamilyCode: string]: {
        code: string;
        label: string;
    };
};

export type State = Immutable<{
    bcAttributes: BCAttributeMap;
    bcAttributeSections: BCSectionMap;
    pimAttributes: PimAttributeMap;
    pimFamilies: PimFamilyMap;
    mapping: Map<string, AttributeMapping>;
    mappingIsDirty: boolean;
}>;

export const initialState: State = {
    bcAttributes: {},
    bcAttributeSections: {},
    pimAttributes: {},
    pimFamilies: {},
    mapping: new Map(),
    mappingIsDirty: false,
};

export type Action =
    | {
          type: 'fetchAllData/fulfilled';
          bcAttributes: BCAttributeMap;
          bcAttributeSections: BCSectionMap;
          pimAttributes: PimAttributeMap;
          pimFamilies: PimFamilyMap;
          mapping: {
              [bcAttributeCode: string]:
                  | {
                        bcAttributeCode: string;
                        hasAttributePerFamily: false;
                        hasCollectionOfAttribute: false;
                        data: string;
                    }
                  | {
                        bcAttributeCode: string;
                        hasAttributePerFamily: true;
                        hasCollectionOfAttribute: false;
                        data: { [pimFamilyCode: string]: string | null };
                    }
                  | {
                        bcAttributeCode: string;
                        hasAttributePerFamily: false;
                        hasCollectionOfAttribute: true;
                        data: string[];
                    }
                  | {
                        bcAttributeCode: string;
                        hasAttributePerFamily: true;
                        hasCollectionOfAttribute: true;
                        data: { [pimFamilyCode: string]: string[] | null };
                    };
          };
      }
    | {
          type: 'saveMapping/fulfilled';
      }
    | {
          type: 'mapping/PimAttributeCodeChanged';
          bcAttributeCode: string;
          pimAttributeCode: string | null;
      }
    | {
          type: 'mapping/PimAttributeCodesChanged';
          bcAttributeCode: string;
          pimAttributeCodes: string[];
      }
    | {
          type: 'mapping/PimAttributeCodePerFamilyChanged';
          bcAttributeCode: string;
          pimFamilyCode: string;
          pimAttributeCode: string | null;
      }
    | {
          type: 'mapping/PimAttributeCodesPerFamilyChanged';
          bcAttributeCode: string;
          pimFamilyCode: string;
          pimAttributeCodes: string[];
      }
    | {
          type: 'mapping/HasAttributePerFamilyChanged';
          bcAttributeCode: string;
          hasAttributePerFamily: boolean;
      };

export const reducer = produce<(draft: State, action: Action) => State>(
    (draft, action) => {
        switch (action.type) {
            case 'fetchAllData/fulfilled':
                draft.bcAttributes = castDraft(action.bcAttributes);
                draft.bcAttributeSections = castDraft(
                    action.bcAttributeSections
                );
                draft.pimAttributes = action.pimAttributes;
                draft.pimFamilies = action.pimFamilies;
                draft.mapping = new Map();
                Object.values(action.mapping).forEach((attributeMapping) => {
                    if (attributeMapping.hasAttributePerFamily) {
                        const familyMappingStatus =
                            Object.keys({ ...attributeMapping.data }).length ===
                            Object.keys(draft.pimFamilies).length
                                ? MappingStatus.Complete
                                : MappingStatus.InProgress;

                        if (attributeMapping.hasCollectionOfAttribute) {
                            draft.mapping.set(
                                attributeMapping.bcAttributeCode,
                                {
                                    bcAttributeCode:
                                        attributeMapping.bcAttributeCode,
                                    hasAttributePerFamily:
                                        attributeMapping.hasAttributePerFamily,
                                    hasCollectionOfAttribute:
                                        attributeMapping.hasCollectionOfAttribute,
                                    pimAttributeCodesPerFamily:
                                        attributeMapping.data,
                                    status: familyMappingStatus,
                                }
                            );
                        } else {
                            draft.mapping.set(
                                attributeMapping.bcAttributeCode,
                                {
                                    bcAttributeCode:
                                        attributeMapping.bcAttributeCode,
                                    hasAttributePerFamily:
                                        attributeMapping.hasAttributePerFamily,
                                    hasCollectionOfAttribute:
                                        attributeMapping.hasCollectionOfAttribute,
                                    pimAttributeCodePerFamily:
                                        attributeMapping.data,
                                    status: familyMappingStatus,
                                }
                            );
                        }
                    } else {
                        if (attributeMapping.hasCollectionOfAttribute) {
                            draft.mapping.set(
                                attributeMapping.bcAttributeCode,
                                {
                                    bcAttributeCode:
                                        attributeMapping.bcAttributeCode,
                                    hasAttributePerFamily:
                                        attributeMapping.hasAttributePerFamily,
                                    hasCollectionOfAttribute:
                                        attributeMapping.hasCollectionOfAttribute,
                                    pimAttributeCodes: attributeMapping.data,
                                    status: MappingStatus.Complete,
                                }
                            );
                        } else {
                            draft.mapping.set(
                                attributeMapping.bcAttributeCode,
                                {
                                    bcAttributeCode:
                                        attributeMapping.bcAttributeCode,
                                    hasAttributePerFamily:
                                        attributeMapping.hasAttributePerFamily,
                                    hasCollectionOfAttribute:
                                        attributeMapping.hasCollectionOfAttribute,
                                    pimAttributeCode: attributeMapping.data,
                                    status: MappingStatus.Complete,
                                }
                            );
                        }
                    }
                });
                draft.mappingIsDirty = false;
                break;

            case 'saveMapping/fulfilled':
                draft.mappingIsDirty = false;
                break;

            case 'mapping/PimAttributeCodeChanged':
                if (null !== action.pimAttributeCode) {
                    draft.mapping.set(action.bcAttributeCode, {
                        bcAttributeCode: action.bcAttributeCode,
                        hasCollectionOfAttribute: false,
                        hasAttributePerFamily: false,
                        pimAttributeCode: action.pimAttributeCode,
                        status: MappingStatus.Complete,
                    });
                } else {
                    draft.mapping.delete(action.bcAttributeCode);
                }
                draft.mappingIsDirty = true;
                break;

            case 'mapping/PimAttributeCodesChanged':
                if (action.pimAttributeCodes.length !== 0) {
                    draft.mapping.set(action.bcAttributeCode, {
                        bcAttributeCode: action.bcAttributeCode,
                        hasCollectionOfAttribute: true,
                        hasAttributePerFamily: false,
                        pimAttributeCodes: action.pimAttributeCodes,
                        status: MappingStatus.Complete,
                    });
                } else {
                    draft.mapping.delete(action.bcAttributeCode);
                }
                draft.mappingIsDirty = true;
                break;

            case 'mapping/HasAttributePerFamilyChanged':
                if (true === action.hasAttributePerFamily) {
                    const attributeMapping = draft.mapping.get(
                        action.bcAttributeCode
                    );

                    const bcAttribute =
                        draft.bcAttributes[action.bcAttributeCode];

                    if (
                        attributeMapping?.hasAttributePerFamily ||
                        bcAttribute === undefined
                    ) {
                        throw new Error();
                    }

                    if (bcAttribute.collection) {
                        let pimAttributeCodesPerFamily = {};
                        if (attributeMapping?.hasCollectionOfAttribute) {
                            // Initialize family mapping with the previously selected pim attributes
                            pimAttributeCodesPerFamily =
                                attributeMapping?.pimAttributeCodes
                                    ? Object.values(draft.pimFamilies).reduce<{
                                          [pimFamilyCode: string]: string[];
                                      }>(
                                          (
                                              pimAttributeCodesPerFamily,
                                              pimFamily
                                          ) => {
                                              pimAttributeCodesPerFamily[
                                                  pimFamily.code
                                              ] =
                                                  attributeMapping.pimAttributeCodes;
                                              return pimAttributeCodesPerFamily;
                                          },
                                          {}
                                      )
                                    : {};
                        }
                        draft.mapping.set(action.bcAttributeCode, {
                            bcAttributeCode: action.bcAttributeCode,
                            hasCollectionOfAttribute: true,
                            hasAttributePerFamily: true,
                            pimAttributeCodesPerFamily,
                            status:
                                Object.keys(pimAttributeCodesPerFamily)
                                    .length ===
                                Object.keys(draft.pimFamilies).length
                                    ? MappingStatus.Complete
                                    : MappingStatus.InProgress,
                        });
                    } else {
                        let pimAttributeCodePerFamily = {};
                        if (!attributeMapping?.hasCollectionOfAttribute) {
                            // Initialize family mapping with the previously selected pim attribute
                            pimAttributeCodePerFamily =
                                attributeMapping?.pimAttributeCode
                                    ? Object.values(draft.pimFamilies).reduce<{
                                          [pimFamilyCode: string]: string;
                                      }>(
                                          (
                                              pimAttributeCodePerFamily,
                                              pimFamily
                                          ) => {
                                              pimAttributeCodePerFamily[
                                                  pimFamily.code
                                              ] =
                                                  attributeMapping.pimAttributeCode;
                                              return pimAttributeCodePerFamily;
                                          },
                                          {}
                                      )
                                    : {};
                        }

                        draft.mapping.set(action.bcAttributeCode, {
                            bcAttributeCode: action.bcAttributeCode,
                            hasCollectionOfAttribute: false,
                            hasAttributePerFamily: true,
                            pimAttributeCodePerFamily,
                            status:
                                Object.keys(pimAttributeCodePerFamily)
                                    .length ===
                                Object.keys(draft.pimFamilies).length
                                    ? MappingStatus.Complete
                                    : MappingStatus.InProgress,
                        });
                    }
                } else {
                    draft.mapping.delete(action.bcAttributeCode);
                }
                draft.mappingIsDirty = true;
                break;

            case 'mapping/PimAttributeCodePerFamilyChanged':
                const attributeMapping = draft.mapping.get(
                    action.bcAttributeCode
                ) || {
                    bcAttributeCode: action.bcAttributeCode,
                    hasAttributePerFamily: true,
                    hasCollectionOfAttribute: false,
                    pimAttributeCodePerFamily: {},
                    status: MappingStatus.InProgress,
                };

                if (
                    !attributeMapping.hasAttributePerFamily ||
                    attributeMapping.hasCollectionOfAttribute
                ) {
                    throw new Error();
                }

                if (null !== action.pimAttributeCode) {
                    attributeMapping.pimAttributeCodePerFamily[
                        action.pimFamilyCode
                    ] = action.pimAttributeCode;
                    const isFamilyMappingComplete =
                        Object.keys(attributeMapping.pimAttributeCodePerFamily)
                            .length === Object.keys(draft.pimFamilies).length;
                    attributeMapping.status = isFamilyMappingComplete
                        ? MappingStatus.Complete
                        : MappingStatus.InProgress;
                } else {
                    delete attributeMapping.pimAttributeCodePerFamily[
                        action.pimFamilyCode
                    ];
                    attributeMapping.status = MappingStatus.InProgress;
                }

                draft.mapping.set(action.bcAttributeCode, attributeMapping);
                draft.mappingIsDirty = true;
                break;

            case 'mapping/PimAttributeCodesPerFamilyChanged':
                const familyAttributeMapping = draft.mapping.get(
                    action.bcAttributeCode
                ) || {
                    bcAttributeCode: action.bcAttributeCode,
                    hasAttributePerFamily: true,
                    hasCollectionOfAttribute: true,
                    pimAttributeCodesPerFamily: {},
                    status: MappingStatus.InProgress,
                };

                if (
                    !familyAttributeMapping.hasAttributePerFamily ||
                    !familyAttributeMapping.hasCollectionOfAttribute
                ) {
                    throw new Error();
                }

                if (action.pimAttributeCodes.length !== 0) {
                    familyAttributeMapping.pimAttributeCodesPerFamily[
                        action.pimFamilyCode
                    ] = action.pimAttributeCodes;
                    const isFamilyMappingComplete =
                        Object.keys(
                            familyAttributeMapping.pimAttributeCodesPerFamily
                        ).length === Object.keys(draft.pimFamilies).length;
                    familyAttributeMapping.status = isFamilyMappingComplete
                        ? MappingStatus.Complete
                        : MappingStatus.InProgress;
                } else {
                    delete familyAttributeMapping.pimAttributeCodesPerFamily[
                        action.pimFamilyCode
                    ];
                    familyAttributeMapping.status = MappingStatus.InProgress;
                }

                draft.mapping.set(
                    action.bcAttributeCode,
                    familyAttributeMapping
                );
                draft.mappingIsDirty = true;
                break;
        }

        return draft;
    }
);
