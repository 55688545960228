import { Immutable } from 'immer';
import { useCurrentStore } from '../../MultiStore';
import {
    isRegisterBCCredentialsValidationError,
    registerBCCredentials,
} from '../api/registerBCCredentials';
import { testBCConnection } from '../api/testBCConnection';
import { BCCredentialsForm } from '../model/BCCredentialsForm';
import { useDispatch } from '../store/StoreProvider';

export const useSaveBCCredentialsForm = () => {
    const dispatch = useDispatch();
    const { currentStoreId } = useCurrentStore();

    return async (form: Immutable<BCCredentialsForm>): Promise<boolean> => {
        if (null === form.data || !form.isDirty) {
            return false;
        }

        try {
            await registerBCCredentials(form.data, currentStoreId);

            const connectionStatus = await testBCConnection(currentStoreId);

            dispatch({
                type: 'registerBCCredentials/fulfilled',
                payload: {
                    connectionStatus,
                },
            });

            return true;
        } catch (error) {
            if (!isRegisterBCCredentialsValidationError(error)) {
                throw error;
            }

            dispatch({
                type: 'registerBCCredentials/rejected',
                errors: error.data.errors,
            });

            return false;
        }
    };
};
