import { Route, Routes } from 'react-router-dom';
import { StoreCreation } from './component/StoreCreation';
import { StoresSelection } from './component/StoresSelection';

export const MultiStore = () => {
    return (
        <Routes>
            <Route path='create' element={<StoreCreation />} />

            <Route path='*' element={<StoresSelection />} />
        </Routes>
    );
};
