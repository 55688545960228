import { apiFetch } from '../../../../../util/apiFetch';
import { AssetMapping } from '../model/AssetMapping';
import { ImageMapping } from '../model/ImageMapping';
import { MediaSource } from '../../../model/MediaSource';
type Result = {
    data:
        | {
              media_source: MediaSource.Images;
              data: ImageMapping;
          }
        | {
              media_source: MediaSource.Assets;
              data: AssetMapping;
          };
};

export const getMediaMapping = (storeId: string) =>
    apiFetch<Result>(`${storeId}/media-mapping`).then((result) => result.data);
