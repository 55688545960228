import { ApiIllustration, Placeholder } from 'akeneo-design-system';
import React from 'react';
import { useIntl } from 'react-intl';

export const DisabledMapping = () => {
    const intl = useIntl();

    const title = intl.formatMessage({
        defaultMessage:
            'Your mapping page is temporarily disabled as a synchronization is in progress.',
        id: 'phHfgu',
    });

    return (
        <Placeholder
            illustration={<ApiIllustration />}
            size='large'
            title={title}
        />
    );
};
