import { Badge, getColor, Table } from 'akeneo-design-system';
import React from 'react';
import { DateTimeLabel } from '../../../component';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import { DeleteStoreButton } from './DeleteStoreButton';
import { Store } from '../api/getStores';
import { SynchronizationHistoryStatusBadge } from '../../Synchronization/HistoryTab/component/SynchronizationHistoryStatusBadge';
import { SynchronizationHistoryStatus } from '../../Synchronization/model/SynchronizationHistoryStatus';

const ClickableTableRow = styled(Table.Row)`
    cursor: pointer;
    &:hover > td {
        background-color: ${getColor('grey', 20)};
    }
`;
const DisabledTableRow = styled(Table.Row)`
    cursor: not-allowed;
    &:hover > td {
        background-color: ${getColor('grey', 20)};
    }
`;

const ActionContainer = styled.div`
    display: flex;
    justify-content: flex-end;
    width: 100%;
`;

const StoreTitleContainer = styled.div`
    color: ${getColor('brand', 100)};
`;

type Props = {
    store: Store;
    handleStoreDeletion: (storeId: string) => Promise<void>;
    isStoreDeletionDisabled: boolean;
};

export const StoreLine = ({
    store,
    handleStoreDeletion,
    isStoreDeletionDisabled,
}: Props) => {
    const navigate = useNavigate();

    const handleStoreSelection = (storeId: string) => {
        navigate(`/${storeId}/configuration`);
    };

    const innerLine = (store: Store) => {
        return (
            <>
                <Table.Cell onClick={() => handleStoreSelection(store.id)}>
                    <StoreTitleContainer>{store.name}</StoreTitleContainer>
                </Table.Cell>
                <Table.Cell onClick={() => handleStoreSelection(store.id)}>
                    <Badge
                        level={
                            store.status === 'CONNECTED' ? 'primary' : 'warning'
                        }
                    >
                        {store.status}
                    </Badge>
                </Table.Cell>
                <Table.Cell onClick={() => handleStoreSelection(store.id)}>
                    {store.lastSynchronizationImport &&
                        store.lastSynchronizationStatus && (
                            <>
                                <DateTimeLabel
                                    dateTime={store.lastSynchronizationImport}
                                />
                            </>
                        )}
                </Table.Cell>
                <Table.Cell onClick={() => handleStoreSelection(store.id)}>
                    {store.lastSynchronizationStatus && (
                        <SynchronizationHistoryStatusBadge
                            status={
                                store.lastSynchronizationStatus === 'SUCCESS'
                                    ? SynchronizationHistoryStatus.Success
                                    : store.lastSynchronizationStatus ===
                                      'WARNING'
                                    ? SynchronizationHistoryStatus.Warning
                                    : store.lastSynchronizationStatus ===
                                      'ERROR'
                                    ? SynchronizationHistoryStatus.Error
                                    : store.lastSynchronizationStatus ===
                                      'STARTED'
                                    ? SynchronizationHistoryStatus.Started
                                    : SynchronizationHistoryStatus.Stopped
                            }
                        />
                    )}
                </Table.Cell>
                <Table.Cell>
                    <ActionContainer>
                        <DeleteStoreButton
                            disabled={isStoreDeletionDisabled}
                            level={'tertiary'}
                            ghost={true}
                            onConfirm={() => handleStoreDeletion(store.id)}
                        />
                    </ActionContainer>
                </Table.Cell>
            </>
        );
    };

    return (
        <>
            {store.status === 'CONNECTED' ? (
                <ClickableTableRow key={`active${store.id}`}>
                    {innerLine(store)}
                </ClickableTableRow>
            ) : (
                <DisabledTableRow key={`disabled${store.id}`}>
                    {innerLine(store)}
                </DisabledTableRow>
            )}
        </>
    );
};
