import React from 'react';
import { Loading } from '../../component';
import { useFetchConfiguration } from './hook/useFetchConfiguration';
import { initialState, reducer } from './store/reducer';
import { DispatchContext, StateContext } from './store/StoreProvider';
import { ConnectionStatus } from './model/ConnectionStatus';
import { InstabilityStickyHelper } from '../../component/PageHeader/InstabilityStickyHelper';

type Props = {
    children: React.ReactNode;
};

export const ConfigurationProvider = ({ children }: Props) => {
    const [state, dispatch] = React.useReducer(reducer, initialState);

    const { isLoading } = useFetchConfiguration(dispatch);

    if (isLoading) {
        return <Loading />;
    }

    return (
        <>
            {ConnectionStatus.Failed === state.bcConnectionStatus && (
                <InstabilityStickyHelper source='BC' />
            )}
            <StateContext.Provider value={state}>
                <DispatchContext.Provider value={dispatch}>
                    {children}
                </DispatchContext.Provider>
            </StateContext.Provider>
        </>
    );
};
