import {
    Button,
    getFontSize,
    Modal,
    StatusIllustration,
} from 'akeneo-design-system';
import React, { useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import styled from 'styled-components';

const Description = styled.div`
    font-size: ${getFontSize('bigger')};
`;

type Props = {
    onConfirm: () => Promise<void>;
    onCancel: () => void;
};

export const DataCleanupConfirmationModal = ({
    onConfirm,
    onCancel,
}: Props) => {
    const intl = useIntl();

    const [disableButtons, setDisableButtons] = useState(false);

    const handleConfirm = async () => {
        setDisableButtons(true);
        await onConfirm();
        setDisableButtons(false);
    };

    return (
        <Modal
            closeTitle={intl.formatMessage({
                defaultMessage: 'Cancel',
                id: '47FYwb',
            })}
            onClose={() => !disableButtons && onCancel()}
            illustration={<StatusIllustration />}
        >
            <Modal.Title>
                <FormattedMessage
                    defaultMessage='Do you want to reset your store data ?'
                    id='z3BQJa'
                />
            </Modal.Title>

            <Description>
                <FormattedMessage
                    defaultMessage='Synchronization issues between Akeneo PIM and your BigCommerce store may occur following your modifications.'
                    id='lCakEt'
                />
                <br />
                <FormattedMessage
                    defaultMessage='The data persistence stored in the App will be erased.'
                    id='gATZ8D'
                />
                <br />
                <FormattedMessage
                    defaultMessage='Please clean up your BigCommerce data before launching a new synchronization.'
                    id='j64f9R'
                />
            </Description>

            <Modal.BottomButtons>
                <Button
                    level='danger'
                    onClick={onCancel}
                    disabled={disableButtons}
                >
                    <FormattedMessage defaultMessage='Cancel' id='47FYwb' />
                </Button>
                <Button
                    level='primary'
                    onClick={handleConfirm}
                    disabled={disableButtons}
                >
                    <FormattedMessage defaultMessage='Confirm' id='N2IrpM' />
                </Button>
            </Modal.BottomButtons>
        </Modal>
    );
};
