import {
    ArrowDownIcon,
    Button,
    Dropdown,
    Link,
    useBooleanState,
} from 'akeneo-design-system';
import { FormattedMessage } from 'react-intl';

type Props = {
    url: string | null;
    bigCommerceUrl: string | null;
};

export const DetailedModalReportDropdown = ({ url, bigCommerceUrl }: Props) => {
    const [isOpen, open, close] = useBooleanState(false);
    return (
        <>
            <Dropdown>
                <Button level='secondary' ghost onClick={open}>
                    <FormattedMessage
                        defaultMessage='Go to product'
                        id='Id0gUT'
                    />
                    <ArrowDownIcon />
                </Button>
                {isOpen && (
                    <Dropdown.Overlay
                        dropdownOpenerVisible={true}
                        verticalPosition='down'
                        onClose={close}
                    >
                        <Dropdown.ItemCollection>
                            {bigCommerceUrl && (
                                <Dropdown.Item>
                                    <Link href={bigCommerceUrl} target='_blank'>
                                        <FormattedMessage
                                            defaultMessage='Go to BigCommerce product'
                                            id='BWUXv7'
                                        />
                                    </Link>
                                </Dropdown.Item>
                            )}
                            {url && (
                                <Dropdown.Item>
                                    <Link href={url} target='_blank'>
                                        <FormattedMessage
                                            defaultMessage='Go to PIM product'
                                            id='udhZHM'
                                        />
                                    </Link>
                                </Dropdown.Item>
                            )}
                        </Dropdown.ItemCollection>
                    </Dropdown.Overlay>
                )}
            </Dropdown>
        </>
    );
};
