import React from 'react';
import { AllowedPimAttributeMultiselect } from '../component/AllowedPimAttributeMultiselect';
import { BCAttribute } from '../../model/BCAttribute';
import { selectAttributeMapping } from '../selector/selectAttributeMapping';
import { useDispatch, useSelector } from '../store/StoreProvider';

type Props = {
    bcAttribute: BCAttribute;
    pimFamilyCode: string;
};

export const FamilyPimAttributeCollectionSelector = ({
    bcAttribute,
    pimFamilyCode,
}: Props) => {
    const dispatch = useDispatch();

    const pimAttributeCodes = useSelector((state) => {
        const attributeMapping = selectAttributeMapping(bcAttribute.code)(
            state
        );
        if (!attributeMapping.hasAttributePerFamily) {
            throw new Error();
        }
        if (!attributeMapping.hasCollectionOfAttribute) {
            throw new Error();
        }

        const attributeCodes =
            attributeMapping.pimAttributeCodesPerFamily[pimFamilyCode];
        if (!attributeCodes) {
            return [];
        }

        return attributeCodes;
    });

    const handleChange = (pimAttributeCodes: string[]) =>
        dispatch({
            type: 'mapping/PimAttributeCodesPerFamilyChanged',
            bcAttributeCode: bcAttribute.code,
            pimFamilyCode,
            pimAttributeCodes,
        });

    return (
        <AllowedPimAttributeMultiselect
            bcAttribute={bcAttribute}
            selectedPimAttributeCodes={pimAttributeCodes as string[]}
            onChange={handleChange}
        />
    );
};
