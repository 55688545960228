import React from 'react';
import { ArrowDownIcon, Dropdown, useBooleanState } from 'akeneo-design-system';
import { useQuery } from 'react-query';
import { getStores } from './api/getStores';
import { FormattedMessage } from 'react-intl';
import { useNavigate } from 'react-router';
import styled from 'styled-components';
import { useCurrentStore } from '.';

const DropdownButton = styled.div`
    font-size: 28px;
    margin-bottom: -28px;
    margin-top: 10px;
    cursor: pointer;
    display: flex;
    align-content: center;
    color: ${({ theme }) => theme.color.brand100};
`;

const DropdownIcon = styled(ArrowDownIcon)`
    margin-left: 10px;
    color: ${({ theme }) => theme.color.grey100};
`;

export const SelectStoreDropdown = () => {
    const navigate = useNavigate();
    const [isOpen, open, close] = useBooleanState(false);

    const { currentStoreId } = useCurrentStore();
    const { isSuccess, data: stores } = useQuery('stores', getStores);
    if (!isSuccess) {
        return null;
    }

    const selectedStoreName = stores.find(
        (store) => store.id === currentStoreId
    )?.name;

    const handleStoreSelection = (storeId: string) => {
        close();
        navigate(`/${storeId}/configuration`);
    };

    return (
        <>
            <Dropdown>
                <DropdownButton onClick={open}>
                    {selectedStoreName}
                    <DropdownIcon></DropdownIcon>
                </DropdownButton>
                {isOpen && (
                    <Dropdown.Overlay
                        dropdownOpenerVisible={true}
                        onClose={close}
                        horizontalPosition='right'
                    >
                        <Dropdown.Header>
                            <Dropdown.Title>
                                <FormattedMessage
                                    id='68x5L8'
                                    defaultMessage='Stores'
                                />
                            </Dropdown.Title>
                        </Dropdown.Header>
                        <Dropdown.ItemCollection>
                            {stores.map((store) => (
                                <Dropdown.Item
                                    key={store.id}
                                    onClick={() =>
                                        handleStoreSelection(store.id)
                                    }
                                >
                                    {store.name}
                                </Dropdown.Item>
                            ))}
                        </Dropdown.ItemCollection>
                    </Dropdown.Overlay>
                )}
            </Dropdown>
        </>
    );
};
