import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Badge } from 'akeneo-design-system';
import { MediaSource } from '../../model/MediaSource';
import { MediaMapping } from '../../ProductVariantMediaMapping/model/MediaMapping';

type Props = {
    attributeMapping: MediaMapping | undefined;
};

export const MediaMappingVariantState = ({ attributeMapping }: Props) => {
    if (
        attributeMapping !== undefined &&
        attributeMapping.attributeCode !== null
    ) {
        return (
            <Badge level='primary'>
                {attributeMapping.source === MediaSource.Images && (
                    <FormattedMessage
                        id='TgW9YV'
                        defaultMessage='Image attribute mapped'
                    />
                )}
                {attributeMapping.source === MediaSource.Assets && (
                    <FormattedMessage
                        id='jeIV6P'
                        defaultMessage='Asset collection mapped'
                    />
                )}
            </Badge>
        );
    }

    return (
        <Badge level='tertiary'>
            <FormattedMessage id='y+FM2U' defaultMessage='Not complete' />
        </Badge>
    );
};
