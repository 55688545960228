import React from 'react';
import { useQuery } from 'react-query';
import { useCurrentStore } from '../MultiStore';
import { isSynchronizationRunning } from './api/isSynchronizationRunning';

type Props = {
    children: React.ReactNode;
    fallback: () => React.ReactNode;
};

export const RunningSynchronizationGuard = ({ children, fallback }: Props) => {
    const { currentStoreId } = useCurrentStore();
    const isRunningSynchroQuery = useQuery(
        [currentStoreId, 'isSynchronizationRunning'],
        () => isSynchronizationRunning(currentStoreId)
    );

    const isSynchroRunning = isRunningSynchroQuery.data;

    if (isSynchroRunning) {
        return <>{fallback()}</>;
    }

    return <>{children}</>;
};
