import { SelectInput } from 'akeneo-design-system';
import { useIntl } from 'react-intl';
import { BCAttribute } from '../../model/BCAttribute';
import { selectAllowedPimAttributes } from '../selector/selectAllowedPimAttributes';
import { useSelector } from '../store/StoreProvider';

type Props = {
    bcAttribute: BCAttribute;
    selectedPimAttributeCode: string | null;
    disabled?: boolean;
    onChange: (pimAttributeCodes: string | null) => void;
};

export const AllowedPimAttributeSelect = ({
    bcAttribute,
    selectedPimAttributeCode,
    disabled,
    onChange,
}: Props) => {
    const intl = useIntl();

    const allowedPimAttributes = useSelector(
        selectAllowedPimAttributes(bcAttribute)
    );

    return (
        <SelectInput
            clearable={true}
            emptyResultLabel=''
            onChange={onChange}
            placeholder={intl.formatMessage({
                id: '+VTndF',
                defaultMessage: 'Select an Akeneo PIM attribute',
            })}
            openLabel=''
            value={selectedPimAttributeCode}
            readOnly={disabled}
        >
            {allowedPimAttributes.map((pimAttribute) => (
                <SelectInput.Option
                    key={pimAttribute.code}
                    value={pimAttribute.code}
                >
                    {pimAttribute.label}
                </SelectInput.Option>
            ))}
        </SelectInput>
    );
};
