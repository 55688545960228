import { apiFetch } from '../../../../../util/apiFetch';
import { MediaSource } from '../../../model/MediaSource';
import { ImageMapping } from '../model/ImageMapping';
import { Immutable } from 'immer';
import { AssetMapping } from '../model/AssetMapping';

type SaveMediaMapping = {
    storeId: string;
    mediaSource: MediaSource;
    data: Immutable<ImageMapping | AssetMapping>;
};

export const saveMediaMapping = (saveMediaMapping: SaveMediaMapping) =>
    apiFetch(`${saveMediaMapping.storeId}/media-mapping`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            media_source: saveMediaMapping.mediaSource,
            data: saveMediaMapping.data,
        }),
    });
