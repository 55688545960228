import styled from 'styled-components';
import {
    AkeneoIcon,
    Dropdown,
    getColor,
    Link,
    useBooleanState,
} from 'akeneo-design-system';
import { helpCenterUrl, supportUrl } from '../util/config';
import { FormattedMessage } from 'react-intl';

const HelpCenter = () => {
    const [isOpen, open, close] = useBooleanState(false);

    return (
        <Container>
            <Dropdown>
                <HelpIconContainer onClick={open}>
                    <HelpIconThemed size={20} />
                </HelpIconContainer>
                {isOpen && (
                    <Dropdown.Overlay
                        onClose={close}
                        verticalPosition='down'
                        horizontalPosition='left'
                    >
                        <Dropdown.Header>
                            <Dropdown.Title>
                                <FormattedMessage
                                    defaultMessage='You need help?'
                                    id='gKdNab'
                                />
                            </Dropdown.Title>
                        </Dropdown.Header>
                        <Dropdown.ItemCollection>
                            <Dropdown.Item>
                                <Link
                                    href={helpCenterUrl}
                                    target='_blank'
                                    style={styleLink}
                                >
                                    <FormattedMessage
                                        defaultMessage='Helpcenter'
                                        id='lTTbfa'
                                    />
                                </Link>
                            </Dropdown.Item>
                            <Dropdown.Item>
                                <Link
                                    href={supportUrl}
                                    target='_blank'
                                    style={styleLink}
                                >
                                    <FormattedMessage
                                        defaultMessage='Support'
                                        id='HqRNN8'
                                    />
                                </Link>
                            </Dropdown.Item>
                        </Dropdown.ItemCollection>
                    </Dropdown.Overlay>
                )}
            </Dropdown>
        </Container>
    );
};

const Container = styled.div`
    margin: 0 20px 0 auto;
    align-self: center;
`;

const HelpIconContainer = styled.div`
    width: 32px;
    height: 32px;
    border: 1px #d9dde2 solid; //Color not in DSM
    border-radius: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
`;

const HelpIconThemed = styled(AkeneoIcon)`
    color: ${getColor('brand', 100)};
`;

const styleLink = {
    width: '180px',
    display: 'block',
};

export { HelpCenter };
