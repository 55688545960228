import { apiFetch } from '../../../util/apiFetch';

type Result = {
    data: Array<{
        code: string;
        type: string;
        label: string;
        attributeTypeLabel: string;
    }>;
};

export const getSelectedCustomFields = (storeId: string) =>
    apiFetch<Result>(`${storeId}/mapping/retrieveCustomFields`).then(
        (result) => result.data
    );
