import { apiFetch } from '../../../util/apiFetch';
import { Synchronization } from '../model/Synchronization';
import { SynchronizationScope } from '../model/SynchronizationScope';
import { SynchronizationStatus } from '../model/SynchronizationStatus';
import { SynchronizationType } from '../model/SynchronizationType';

const mapSynchronizationType = (type: 'FULL' | 'DIFFERENTIAL') => {
    switch (type) {
        case 'FULL':
            return SynchronizationType.Full;
        case 'DIFFERENTIAL':
            return SynchronizationType.Differential;
    }
};

const mapSynchronizationStatus = (
    status: 'PENDING' | 'DISABLED' | 'IN PROGRESS' | 'READY'
) => {
    switch (status) {
        case 'PENDING':
            return SynchronizationStatus.Pending;
        case 'DISABLED':
            return SynchronizationStatus.Disabled;
        case 'IN PROGRESS':
            return SynchronizationStatus.InProgress;
        case 'READY':
            return SynchronizationStatus.Ready;
    }
};

const createSynchronization = (
    scope: SynchronizationScope,
    result: SynchronizationResult
): Synchronization => {
    const status = mapSynchronizationStatus(result.status);

    if (
        status === SynchronizationStatus.Ready ||
        status === SynchronizationStatus.Disabled
    ) {
        return {
            scope,
            status,
            frequency: result.frequency,
            scheduledAt: result.scheduled_at,
            filterAttribute: result.filter_attribute,
            priceImport: result.price_import,
            withCustomFields: result.with_custom_fields,
            withImages: result.with_images,
            withImportPreExistingProductsOnly:
                result.with_import_pre_existing_products_only,
        };
    }

    const type = mapSynchronizationType(result.type!);

    if (type === SynchronizationType.Full) {
        return {
            scope,
            status,
            type,
            createdAt: result.created_at!,
            id: result.id!,
            frequency: result.frequency,
            scheduledAt: result.scheduled_at,
            filterAttribute: result.filter_attribute,
            priceImport: result.price_import,
            withCustomFields: result.with_custom_fields,
            withImages: result.with_images,
            withImportPreExistingProductsOnly:
                result.with_import_pre_existing_products_only,
        };
    }

    return {
        scope,
        status,
        type,
        createdAt: result.created_at!,
        referenceDate: result.reference_date!,
        id: result.id!,
        frequency: result.frequency,
        scheduledAt: result.scheduled_at,
        filterAttribute: result.filter_attribute,
        priceImport: result.price_import,
        withCustomFields: result.with_custom_fields,
        withImages: result.with_images,
        withImportPreExistingProductsOnly:
            result.with_import_pre_existing_products_only,
    };
};

type SynchronizationResult = {
    status: 'READY' | 'DISABLED' | 'PENDING' | 'IN PROGRESS';
    type: 'FULL' | 'DIFFERENTIAL' | null;
    reference_date: string | null;
    created_at: string | null;
    id: string | null;
    scheduled_at: string;
    frequency: string;
    filter_attribute: string | null;
    price_import: boolean;
    with_custom_fields: boolean;
    with_images: boolean;
    with_import_pre_existing_products_only: boolean;
};

type Result = {
    [key in 'PRODUCT' | 'CATEGORY' | 'PRODUCT_VARIANT']: SynchronizationResult;
};

export const fetchSynchronizations = (
    storeId: string
): Promise<{
    [key in SynchronizationScope]: Synchronization;
}> =>
    apiFetch<Result>(
        `${storeId}/synchronization/retrieveExecutingSynchronizations`
    ).then((result) => ({
        [SynchronizationScope.Product]: createSynchronization(
            SynchronizationScope.Product,
            result.PRODUCT
        ),
        [SynchronizationScope.Category]: createSynchronization(
            SynchronizationScope.Category,
            result.CATEGORY
        ),
        [SynchronizationScope.ProductVariant]: createSynchronization(
            SynchronizationScope.ProductVariant,
            result.PRODUCT_VARIANT
        ),
    }));
