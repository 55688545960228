import { SelectInput } from 'akeneo-design-system';
import { useIntl } from 'react-intl';
import { PimAttribute } from '../model/PimAttribute';

type Props = {
    selectedPimAttributeCode: string | null;
    disabled?: boolean;
    allowedPimAttributes: PimAttribute[];
    onChange: (pimAttributeCode: string | null) => void;
};

export const AllowedPimVariantAttributeSelect = ({
    selectedPimAttributeCode,
    disabled,
    allowedPimAttributes,
    onChange,
}: Props) => {
    const intl = useIntl();

    return (
        <SelectInput
            clearable={true}
            emptyResultLabel=''
            onChange={onChange}
            placeholder={intl.formatMessage({
                id: '+VTndF',
                defaultMessage: 'Select an Akeneo PIM attribute',
            })}
            openLabel=''
            value={selectedPimAttributeCode}
            readOnly={disabled}
        >
            {allowedPimAttributes.map((pimAttribute) => (
                <SelectInput.Option
                    key={pimAttribute.code}
                    value={pimAttribute.code}
                >
                    {pimAttribute.label}
                </SelectInput.Option>
            ))}
        </SelectInput>
    );
};
