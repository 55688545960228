import styled from 'styled-components';
import { Helper } from 'akeneo-design-system';
import { FormattedMessage } from 'react-intl';
import React from 'react';

type Props = {
    source: 'BC' | 'PIM';
};

export const InstabilityStickyHelper = ({ source }: Props) => {
    return (
        <StyledHelper level='warning' sticky={0}>
            {source === 'BC' && (
                <FormattedMessage
                    defaultMessage="BigCommerce's API is currently experiencing instability. Please reload the page."
                    id='2RIEGl'
                />
            )}
            {source === 'PIM' && (
                <FormattedMessage
                    defaultMessage="Akeneo's API is currently experiencing instability. Please reload the page."
                    id='dm8he8'
                />
            )}
        </StyledHelper>
    );
};

const StyledHelper = styled(Helper)`
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
`;
