import React, { useState } from 'react';
import { useQuery } from 'react-query';
import { Loading } from '../../../component';
import { useCurrentStore } from '../../MultiStore';
import { getBCAttributes } from '../api/getBCAttributes';
import { getMapping } from '../api/getMapping';
import { getPimAttributes } from '../api/getPimAttributes';
import { getPimFamilies } from '../api/getPimFamilies';
import { initialState, reducer } from './store/reducer';
import { DispatchContext, StateContext } from './store/StoreProvider';
import { InstabilityStickyHelper } from '../../../component/PageHeader/InstabilityStickyHelper';

type Props = {
    children: React.ReactNode;
};

export const ProductMappingProvider = ({ children }: Props) => {
    const [state, dispatch] = React.useReducer(reducer, initialState);
    const [pimHasInstability, setPimHasInstability] = useState<boolean>(false);

    const { currentStoreId } = useCurrentStore();
    const { isFetching } = useQuery(
        'fetchAllData',
        () =>
            Promise.all([
                getBCAttributes(currentStoreId),
                getPimAttributes(currentStoreId),
                getPimFamilies(currentStoreId),
                getMapping(currentStoreId),
            ]),
        {
            onSuccess: ([
                bcAttributesResult,
                pimAttributes,
                pimFamilies,
                mapping,
            ]) => {
                if (
                    pimFamilies.error &&
                    pimFamilies.error === 'pim_instability'
                ) {
                    setPimHasInstability(true);

                    return;
                }

                if (
                    pimAttributes.error &&
                    pimAttributes.error === 'pim_instability'
                ) {
                    setPimHasInstability(true);

                    return;
                }

                dispatch({
                    type: 'fetchAllData/fulfilled',
                    bcAttributes: bcAttributesResult.bcAttributes,
                    bcAttributeSections: bcAttributesResult.bcAttributeSections,
                    pimAttributes,
                    pimFamilies,
                    mapping,
                });
            },
        }
    );

    if (isFetching) {
        return <Loading />;
    }

    return (
        <>
            {pimHasInstability && <InstabilityStickyHelper source='PIM' />}
            <StateContext.Provider value={state}>
                <DispatchContext.Provider value={dispatch}>
                    {children}
                </DispatchContext.Provider>
            </StateContext.Provider>
        </>
    );
};
