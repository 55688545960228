import { apiFetch } from '../../../util/apiFetch';

type Result = {
    [bcAttributeCode: string]:
        | {
              bcAttributeCode: string;
              hasAttributePerFamily: false;
              hasCollectionOfAttribute: false;
              data: string;
          }
        | {
              bcAttributeCode: string;
              hasAttributePerFamily: true;
              hasCollectionOfAttribute: false;
              data: { [pimFamilyCode: string]: string | null };
          }
        | {
              bcAttributeCode: string;
              hasAttributePerFamily: false;
              hasCollectionOfAttribute: true;
              data: string[];
          }
        | {
              bcAttributeCode: string;
              hasAttributePerFamily: true;
              hasCollectionOfAttribute: true;
              data: { [pimFamilyCode: string]: string[] | null };
          };
};

export const getMapping = (storeId: string) =>
    apiFetch<Result>(`${storeId}/mapping`);
