import { apiFetch } from '../../../util/apiFetch';

type Result = {
    [code: string]: {
        code: string;
        type: string;
        label: string;
        constraint: string;
        pimAllowedTypes: string[];
        isRequired: boolean;
    };
};

export const fetchBCVariantAttributes = (storeId: string) =>
    apiFetch<Result>(`${storeId}/mapping/retrieveBCVariantAttributes`);
