import { apiFetch } from '../../../util/apiFetch';

type Result = {
    data: {
        storefront_id: string;
        display_option_type_code: string;
    };
};

export const getBcConfig = (storeId: string) =>
    apiFetch<Result>(`${storeId}/configuration/retrieveBcConfig`).then(
        (result) => ({
            storefrontId: result.data.storefront_id,
            displayOptionTypeCode: result.data.display_option_type_code,
        })
    );
