import { apiFetch } from './apiFetch';

type Result = {
    pimVersion: string | null;
};

export const getPimVersion = () =>
    apiFetch<Result>('retrievePimVersion').then((result) => {
        return result.pimVersion;
    });
