import {
    Button,
    ChannelsIllustration,
    Modal,
    TextInput,
    Field,
    Link,
    getColor,
    Helper,
} from 'akeneo-design-system';
import React, { useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useMutation } from 'react-query';
import { useNavigate } from 'react-router';
import styled from 'styled-components';
import { BadRequestError } from '../../../util/apiFetch';
import { createStore } from '../api/createStore';
import { helpCenterCreateStoreUrl } from '../../../util/config';

const Title = styled.div`
    color: ${getColor('brand', 100)};
`;

export const StoreCreation = () => {
    const intl = useIntl();
    const navigate = useNavigate();

    const [form, setForm] = useState({
        name: '',
        hash: '',
    });

    const [formError, setError] = useState({
        name: null,
        hash: null,
    });

    const mutation = useMutation('store', createStore, {
        useErrorBoundary: false,
    });

    const onSubmit = async () => {
        try {
            const store = await mutation.mutateAsync(form);
            navigate(`/${store}/configuration`);
        } catch (error) {
            if (error instanceof BadRequestError) {
                setError(error.data.errors);
            }
        }
    };

    return (
        <Modal
            closeTitle={intl.formatMessage({
                id: '47FYwb',
                defaultMessage: 'Cancel',
            })}
            onClose={() => navigate('/')}
            illustration={<ChannelsIllustration />}
        >
            <Modal.TopRightButtons>
                <Button
                    level='primary'
                    onClick={onSubmit}
                    disabled={mutation.isLoading}
                >
                    <FormattedMessage id='N2IrpM' defaultMessage='Confirm' />
                </Button>
            </Modal.TopRightButtons>

            <Title>
                <FormattedMessage id='lyamV1' defaultMessage='NEW STORE' />
            </Title>

            <Modal.Title>
                <FormattedMessage
                    id='mBkJYe'
                    defaultMessage='Create a new store'
                />
            </Modal.Title>

            <Helper level='info'>
                <FormattedMessage
                    id='Z0kz8L'
                    defaultMessage='If you need help please check our article'
                />{' '}
                <Link href={helpCenterCreateStoreUrl} target='_blank'>
                    <FormattedMessage
                        id='mBkJYe'
                        defaultMessage='Create a new store'
                    />
                </Link>
            </Helper>

            <br />

            <form autoComplete='off'>
                <Field
                    label={intl.formatMessage({
                        id: 'HAlOn1',
                        defaultMessage: 'Name',
                    })}
                >
                    <TextInput
                        value={form.name}
                        data-testid='storename'
                        readOnly={false}
                        invalid={!!formError?.name}
                        autoComplete='off'
                        onChange={(name) => {
                            setForm({
                                ...form,
                                name,
                            });
                        }}
                    />
                    {formError?.name && (
                        <Helper inline level='error'>
                            {formError.name === 'invalid_store_name' && (
                                <FormattedMessage
                                    id='z9UnBv'
                                    defaultMessage='Name is required and must be less than 50 characters'
                                />
                            )}
                        </Helper>
                    )}
                </Field>

                <br />
                <Field
                    label={intl.formatMessage({
                        id: 'UHGqvP',
                        defaultMessage: 'Store hash',
                    })}
                >
                    <TextInput
                        value={form.hash}
                        data-testid='hash'
                        readOnly={false}
                        invalid={!!formError?.hash}
                        autoComplete='off'
                        onChange={(hash) => {
                            setForm({
                                ...form,
                                hash,
                            });
                        }}
                    />
                    {formError?.hash && (
                        <Helper inline level='error'>
                            {formError.hash === 'store_hash_not_unique' && (
                                <FormattedMessage
                                    id='cin3oT'
                                    defaultMessage='This store hash already exists'
                                />
                            )}
                            {formError.hash === 'invalid_store_hash' && (
                                <FormattedMessage
                                    id='FzjT/u'
                                    defaultMessage='The Store hash format is invalid'
                                />
                            )}
                        </Helper>
                    )}
                </Field>
            </form>
        </Modal>
    );
};
