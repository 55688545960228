import { Badge } from 'akeneo-design-system';
import { FormattedMessage } from 'react-intl';
import { SynchronizationHistoryStatus } from '../../model/SynchronizationHistoryStatus';

type Props = {
    status: SynchronizationHistoryStatus;
};

export const SynchronizationHistoryStatusBadge = ({ status }: Props) => {
    switch (status) {
        case SynchronizationHistoryStatus.Success:
            return (
                <Badge level='primary'>
                    <FormattedMessage id='xrKHS6' defaultMessage='Success' />
                </Badge>
            );
        case SynchronizationHistoryStatus.Warning:
            return (
                <Badge level='warning'>
                    <FormattedMessage id='3SVI5p' defaultMessage='Warning' />
                </Badge>
            );
        case SynchronizationHistoryStatus.Error:
            return (
                <Badge level='danger'>
                    <FormattedMessage id='KN7zKn' defaultMessage='Error' />
                </Badge>
            );
        case SynchronizationHistoryStatus.Stopped:
            return (
                <Badge level='danger'>
                    <FormattedMessage id='1LBny5' defaultMessage='Stopped' />
                </Badge>
            );
        case SynchronizationHistoryStatus.Started:
            return (
                <Badge level='secondary'>
                    <FormattedMessage
                        defaultMessage='In progress'
                        id='q1WWIr'
                    />
                </Badge>
            );
    }
};
