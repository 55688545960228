import { Table } from 'akeneo-design-system';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';

const Container = styled.div`
    margin-top: 10px;
`;

type Props = {
    children: React.ReactNode;
};

export const MappingList = ({ children }: Props) => (
    <Container>
        <Table>
            <Table.Header>
                <Table.HeaderCell>
                    <FormattedMessage
                        id='a0cVxV'
                        defaultMessage='BigCommerce attribute'
                    />
                </Table.HeaderCell>
                <Table.HeaderCell>
                    <FormattedMessage
                        id='h4QvQI'
                        defaultMessage='PIM attribute'
                    />
                </Table.HeaderCell>
                <Table.HeaderCell>
                    <FormattedMessage
                        id='4wqy1m'
                        defaultMessage='Mapping state'
                    />
                </Table.HeaderCell>
                <Table.HeaderCell></Table.HeaderCell>
            </Table.Header>
            <Table.Body>
                <>{children}</>
            </Table.Body>
        </Table>
    </Container>
);
