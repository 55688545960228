import { useQuery } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { useCurrentStore } from '../../MultiStore';
import { EditMediaMappingButton } from '../component/MediaMapping/EditMediaMappingButton';
import { fetchProductVariantMediaMapping } from '../ProductVariantMediaMapping';
import { MappingItem } from './component/MappingItem';
import { MediaMappingVariantState } from './component/MediaMappingVariantState';
import { BCVariantAttribute } from './model/BCVariantAttribute';

type Props = {
    bcVariantAttribute: BCVariantAttribute;
};

export const MediaMappingItem = ({ bcVariantAttribute }: Props) => {
    const navigate = useNavigate();

    const { currentStoreId } = useCurrentStore();
    const { data } = useQuery('fetchProductVariantMediaMapping', () =>
        fetchProductVariantMediaMapping(currentStoreId)
    );
    return (
        <MappingItem
            key={bcVariantAttribute.code}
            bcVariantAttribute={bcVariantAttribute}
            mappingState={<MediaMappingVariantState attributeMapping={data} />}
        >
            <div>
                <EditMediaMappingButton
                    onClick={() => navigate('variant/media-mapping')}
                />
            </div>
        </MappingItem>
    );
};
