import { apiFetch } from '../../../util/apiFetch';

type FormValue = {
    name: string;
    hash: string;
};
type Result = {
    name: string;
    hash: string;
    id: string;
};
export const createStore = (formValue: FormValue) => {
    return apiFetch<Result>('store', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(formValue),
    });
};
