import { FormattedMessage } from 'react-intl';
import { SynchronizationHistoryStatus } from '../../model/SynchronizationHistoryStatus';

type Props =
    | {
          status:
              | SynchronizationHistoryStatus.Success
              | SynchronizationHistoryStatus.Error
              | SynchronizationHistoryStatus.Stopped;
      }
    | {
          status: SynchronizationHistoryStatus.Warning;
          warningCount: number;
          errorCount: number;
      };

export const SynchronizationHistoryStatusMessage = (props: Props) => {
    switch (props.status) {
        case SynchronizationHistoryStatus.Success:
            return (
                <FormattedMessage
                    defaultMessage='Your synchronization has been completed successfully, without warnings or errors.'
                    id='78RrrV'
                />
            );
        case SynchronizationHistoryStatus.Warning:
            return (
                <FormattedMessage
                    defaultMessage='Your synchronizations has been completed with {warningCount} warnings and {errorCount} errors.'
                    id='jdreeG'
                    values={{
                        warningCount: props.warningCount,
                        errorCount: props.errorCount,
                    }}
                />
            );
        case SynchronizationHistoryStatus.Error:
            return (
                <FormattedMessage
                    defaultMessage='Your synchronization could not be completed and has been stopped.'
                    id='BYsv1F'
                />
            );
        case SynchronizationHistoryStatus.Stopped:
            return (
                <FormattedMessage
                    defaultMessage='Your synchronization has been stopped.'
                    id='sTgzTa'
                />
            );
    }
};
