import { Dispatch } from 'react';
import { useQuery } from 'react-query';
import { useCurrentStore } from '../../MultiStore';
import { getBcConfig } from '../api/getBcConfig';
import { getBCCredentials } from '../api/getBCCredentials';
import { getConnectorConfig } from '../api/getConnectorConfig';
import { testBCConnection } from '../api/testBCConnection';
import { Action } from '../store/reducer';

export const useFetchConfiguration = (dispatch: Dispatch<Action>) => {
    const { currentStoreId } = useCurrentStore();

    return useQuery(
        [currentStoreId, 'fetchConfiguration'],
        () =>
            Promise.all([
                getBCCredentials(currentStoreId),
                getConnectorConfig(currentStoreId),
                getBcConfig(currentStoreId),
                testBCConnection(currentStoreId),
            ]),
        {
            onSuccess: ([
                bcCredentials,
                connectorConfig,
                connectorBcConfig,
                bcConnectionStatus,
            ]) => {
                const payload = {
                    bcCredentials: bcCredentials,
                    connectorConfig: {
                        categoryCode: connectorConfig?.categoryCode || '',
                        locale: connectorConfig?.locale || '',
                        channel: connectorConfig?.channel || '',
                        currency: connectorConfig?.currency || '',
                        pre_existing_catalog_mapping: {
                            bigcommerce_product_identifier:
                                connectorConfig?.pre_existing_catalog_mapping
                                    ?.product_mapping
                                    .bigcommerce_product_identifier || null,
                            pim_product_identifier:
                                connectorConfig?.pre_existing_catalog_mapping
                                    ?.product_mapping.pim_product_identifier ||
                                null,
                            bigcommerce_variant_identifier:
                                connectorConfig?.pre_existing_catalog_mapping
                                    ?.variant_mapping
                                    .bigcommerce_variant_identifier || null,
                            pim_variant_identifier:
                                connectorConfig?.pre_existing_catalog_mapping
                                    ?.variant_mapping.pim_variant_identifier ||
                                null,
                        },
                    },
                    connectorBcConfig: connectorBcConfig,
                    bcConnectionStatus: bcConnectionStatus,
                };

                dispatch({
                    type: 'fetchConfiguration/fulfilled',
                    payload: payload,
                });
            },
        }
    );
};
