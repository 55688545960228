import { BCVariantAttribute } from './model/BCVariantAttribute';
import { AllowedPimVariantAttributeSelect } from './component/AllowedPimVariantAttributeSelect';
import { useAllowedPimAttributes } from './hook/useAllowedPimAttributes';
import { useDispatch, useSelector } from './store/StoreProvider';
import { State } from './store/reducer';

type Props = {
    bcVariantAttribute: BCVariantAttribute;
};

export const PimVariantAttributeSelector = ({ bcVariantAttribute }: Props) => {
    const allowedPimAttributes = useAllowedPimAttributes(bcVariantAttribute);
    const dispatch = useDispatch();

    const attributeMapping = useSelector((state: State) => {
        return state.productVariantMapping.get(bcVariantAttribute.code);
    });

    const handleChange = (pimAttributeCode: string | null) => {
        if (pimAttributeCode !== null) {
            dispatch({
                type: 'variantAttributeMapped',
                bcAttributeCode: bcVariantAttribute.code,
                pimAttributeCode,
            });
        } else {
            dispatch({
                type: 'variantAttributeUnmapped',
                bcAttributeCode: bcVariantAttribute.code,
            });
        }
    };

    return (
        <AllowedPimVariantAttributeSelect
            selectedPimAttributeCode={
                attributeMapping ? attributeMapping.data : null
            }
            disabled={false}
            allowedPimAttributes={allowedPimAttributes}
            onChange={handleChange}
        />
    );
};
