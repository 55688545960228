import { ChannelsIllustration, Link, Placeholder } from 'akeneo-design-system';
import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { helpCenterStoreListUrl } from '../../../util/config';

export const NoStore = () => {
    const intl = useIntl();

    const title = intl.formatMessage({
        id: 'h6mHSJ',
        defaultMessage: 'There is no store',
    });

    return (
        <>
            <Placeholder
                illustration={<ChannelsIllustration />}
                size='large'
                title={title}
            >
                <Link href={helpCenterStoreListUrl} target='_blank'>
                    <FormattedMessage
                        id='43uZEt'
                        defaultMessage='Visit our Help Center for more information'
                    />
                </Link>
            </Placeholder>
        </>
    );
};
